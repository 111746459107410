import * as React from 'react';
import PropTypes from 'prop-types';
import Amplify, { Storage } from 'aws-amplify';

// @mui
import { Stack, Box, Card, IconButton, Typography, CardActionArea } from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
// components
import Label from '../../../components/label';

import { ASSET } from '../../../constants';
import { PHOTOGRAPHY_INFO } from '../../../constants';
import { STRINGS } from '../../../strings';
import Button from '../../../theme/overrides/Button';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

PhotographyCard.propTypes = {
  photography: PropTypes.object,
  customer: PropTypes.object,
  onDelete: PropTypes.func
};

export default function PhotographyCard({ photography, customer, onClick, onDelete }) {
  const [coverImage, setCoverImage] = React.useState(ASSET.NO_IMAGE);
  const [photographyStatus, setPhotographyStatus] = React.useState('');
  const [name, setName] = React.useState('');
  const shootingDate = photography.shootingDate;

  React.useEffect(() => {
    if (customer) {
      setName(customer.family_name + customer.first_name + "様 " + PHOTOGRAPHY_INFO[photography.title].name + ' ' + photography.course);
    }

    // S3 のフォルダのリストを取得
    Storage.list('thumbnail/' + photography.id + '/')
    .then(result => {
      // console.log(result);
      if (result.results.length > 0) {
        // S3 のフォルダに画像ファイルが入っていれば先頭のファイルを表示
        Storage.get(result.results[0].key)
        .then(result => {
          if (result) {
            setCoverImage(result);
          }
        })
        .catch(err => {
          console.log(err)
        });        
        // 選択ステータスを設定
        let statusStr = '';
        if (photography.isPrintConfirmed != null && photography.isPrintConfirmed) {
          statusStr = STRINGS.STATUS_CONFIRMED_PHOTO;
        } else if (photography.isSelected) {
          statusStr = STRINGS.STATUS_SELECTED_PHOTO;
        } else {
          statusStr = STRINGS.STATUS_SELECTING_PHOTO;
        }
        setPhotographyStatus(statusStr);
      } else {
        // 画像ファイルが 0 であればステータスを[アップロード待ち]
        setPhotographyStatus(STRINGS.STATUS_NO_UPLOAD);
      }
    })
    .catch(err => {
      console.log(err)
    });
  }, [])

  const handleCkick = () => {
    onClick(photography);
  };

  const handleDelete = () => {
    onDelete(photography);
  };

  const handleImageError = (event) => {
    // サムネイルが出来上がっていないパターン
    event.target.onError = null;
    setCoverImage(coverImage.replace('thumbnail', 'image'));
  }

  return (
    <Card>
      <CardActionArea onClick={handleCkick}>

      <Box sx={{ pt: '100%', position: 'relative' }}>
        {photographyStatus && (
          <Label
            variant="filled"
            color={photographyStatus === STRINGS.STATUS_CONFIRMED_PHOTO ? 'success' : (photographyStatus === STRINGS.STATUS_SELECTED_PHOTO && 'info') || 'error'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {photographyStatus}
          </Label>
        )}
        <StyledProductImg alt={name} src={coverImage} onError={event => {handleImageError(event)}} />
      </Box>
      </CardActionArea>

      <Stack spacing={1} sx={{ p: 1 }}>
      <Box sx={{ position: 'relative' }}>
        <Typography variant="subtitle3" noWrap>
          {name}
        </Typography>
        <br/>
        <Typography variant="subtitle3" noWrap
            sx={{
              color: 'text.disabled',
            }}
        >
          {shootingDate}
        </Typography>
        </Box>
      </Stack>
      <Box 
            position={'absolute'}
            sx={{
              zIndex: 9,
              bottom: -5,
              right: -1,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
        >
        <IconButton size="large" onClick={handleDelete}>
          <DeleteIcon fontSize="inherit" />
        </IconButton>

      </Box>
    </Card>
  );
}
