// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export const StyledSelectFooter = styled(Box)(({ theme, ownerState }) => {
  return {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
  };
});
