import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {animateScroll as scroll} from 'react-scroll';
// @mui
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
import { Stepper, Step, StepLabel } from '@mui/material';
// amplify
import { API, graphqlOperation, Storage } from "aws-amplify";
import { listPhotos, getPhotography } from "../graphql/queries";
// components
import { PhotoCard } from '../sections/@dashboard/photography';
import SelectFooter from '../components/footer/SelectFooter';
import Logo from '../components/logo/Logo';
import PhotographyOptionSelectDialog from '../components/dialog/PhotographyOptionSelectDialog';
import PhotographyCopySelectDialog from '../components/dialog/PhotographyCopySelectDialog';
import PhotoSelectCopyInfoDialog from '../components/dialog/PhotoSelectCopyInfoDialog';
import PhotoImageZoomModal from '../components/modal/PhotoImageZoomModal';
// other
import { PHOTOGRAPHY_INFO, SAMPLE_PRESENT_INFO, SERVICE_INFO } from '../constants';
import { TYPE_HYAKUNICHI } from '../constants';
import { PATH } from '../constants';
import { OPTION_INFO, COPY_INFO } from '../constants';
import { STRINGS } from '../strings';

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' },
];

const PHOTO_SELECT_STATE = {
  IDLE: 0,
  COVER: 1,
  BIG: 2,
  SMALL: 3,
  INSIDE: 4,
  UNIQUE: 5,
  SERVICE: 6,
  PRESENT: 7,
  OPTION: 8,
  COPY: 9
}

// ----------------------------------------------------------------------
export default function PhotoSelectPage() {
  const { photographyID } = useParams();
  
  const [selectState, setSelectState] = React.useState(PHOTO_SELECT_STATE.IDLE);
  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setSteps] = React.useState([]);

  const [selectPhotograpyTitle, setTitle] = React.useState('');
  const [selectDescriptionString, setSelectDescriptionString] = React.useState('');
  const [photoAndCheckedList, setPhotoAndCheckedList] = React.useState([]);
  const [remainingNumber, setRemainingNumber] = React.useState(0);
  const [isNextBtnEnable, setIsNextBtnEnable] = React.useState(false);
  const [isNegativeBtnVisible, setIsNegativeBtnVisible] = React.useState(false);
  const [isDoneString, setIsDoneString] = React.useState(false);
  const [isRemainingNumberDisp, setIsRemainingNumberDisp] = React.useState(false);
  const [isOptionResetButtonDisp, setIsOptionResetButtonDisp] = React.useState(false);

  const [isOptionSelectDialogOpen, setIsOptionSelectDialogOpen] = React.useState(false);
  const [isCopyInfoDialogOpen, setIsCopyInfoDialogOpen] = React.useState(false);
  const [isCopySelectDialogOpen, setIsCopySelectDialogOpen] = React.useState(false);
  const [currentServiceNum, setCurrentServiceNum] = React.useState(0);
  const [currentOptionNum, setCurrentOptionNum] = React.useState(0);

  const [photography, setPhotography] = React.useState();
  const [optionCountList, setOptionCountList] =
    React.useState(Array.from({ length: Object.keys(OPTION_INFO).length }, () => 0));
  const [isOptionDecideBtnEnable, setIsOptionDecideBtnEnable] = React.useState(false);
  const [copySelectPhoto, setCopySelectPhoto] = React.useState();
  const [copySelectPhotoIndex, setCopySelectPhotoIndex] = React.useState(0);
  const [copyCountList, setCopyCountList] =
    React.useState(Array.from({ length: Object.keys(COPY_INFO).length }, () => 0));
  const [isCopyDialogDoneBtnEnable, setCopyDialogDoneBtnEnable] = React.useState(false);

  const [isPhotoZoomModalOpen, setIsPhotoZoomModalOpen] = React.useState(false);
  const [imageViewerUrl, setImageViewerUrl] = React.useState('');

  const isRunning = React.useRef(false) // スクロール多発防止用フラグ

  const navigate = useNavigate();

  const [isPending, startTransition] = React.useTransition({
    timeoutMs: 3000 // タイムアウトを10秒に設定
  });

  // そのうちリファクタ
  let renderUsePhotography = undefined;
  let renderUseSteps = [];
  
  const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
  };

  const wapper = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  };

  React.useEffect(() => {
    let loadPhotography = loadPhotoSelectLocalStorage()
    if (!loadPhotography) {
      changeInitialState();
      // 撮影情報を取得
      fetchPhotography((argPhotography) => {
        if (argPhotography.isSelected) {
          // すでに写真が選択されている場合は確認完了画面へ繊維
          navigate(PATH.SELECT_DONE + argPhotography.id);
          return;
        }
        setTitle(argPhotography.shootingDate + ' ' + PHOTOGRAPHY_INFO[argPhotography.title].name);
        generatePhotographySteps(argPhotography);
        fetchPhotos();
      });
    } else {
      var steps = generatePhotographySteps(loadPhotography);
      if (loadPhotography.isSelected) {
        // すでに写真が選択されている場合は確認完了画面へ繊維
        navigate(PATH.SELECT_DONE + loadPhotography.id);
        return;
      }
      setSteps(steps);
      changeCopyMode(steps);
    }
    // スクロールイベントの取得
    document.addEventListener('scroll', isScrollToggle, { passive: true })
    return () => {
      document.removeEventListener('scroll', isScrollToggle, { passive: true })
    }
  }, [])

  React.useEffect(() => {
    if (photoAndCheckedList.length == 0) {
      return;
    }
    if (selectState == PHOTO_SELECT_STATE.IDLE) {
      changeNextState(photography, steps);
    }
  }, [photoAndCheckedList])

  // スクロールイベントのリスナに登録する関数
  const isScrollToggle = React.useCallback(() => {
    if (isRunning.current) return
    isRunning.current = true
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    // console.log(scrollTop);
    requestAnimationFrame(() => {
      isRunning.current = false
    })
  }, [])

  const savePhotoSelectLocalStorage = () => {
    localStorage.setItem('photography', JSON.stringify(photography));
    localStorage.setItem('photos', JSON.stringify(photography.photos));
    localStorage.setItem('photoAndCheckedList', JSON.stringify(photoAndCheckedList));
    localStorage.setItem('currentServiceNum', JSON.stringify(currentServiceNum));
    localStorage.setItem('currentOptionNum', JSON.stringify(currentOptionNum));
    localStorage.setItem('optionCountList', JSON.stringify(optionCountList));
    localStorage.setItem('copyCountList', JSON.stringify(copyCountList));  
  };

  const loadPhotoSelectLocalStorage = () => {
    let photography = JSON.parse(localStorage.getItem('photography'));
    // もし localStorage に残ってる場合、ゴミが表示される可能性があるのでチェックしておく
    if (photography != null && photography.id != photographyID) {
      // id が違う場合はもう一度フェッチ
      return null;
    }
    setPhotography(photography);
    localStorage.removeItem('photography')
    
    if (localStorage.hasOwnProperty('photos')) {
      photography.photos = JSON.parse(localStorage.getItem('photos'));
      localStorage.removeItem('photos');
    }
    if (localStorage.hasOwnProperty('photoAndCheckedList')) {
      setPhotoAndCheckedList(JSON.parse(localStorage.getItem('photoAndCheckedList')));
      localStorage.removeItem('photoAndCheckedList');
    }
    if (localStorage.hasOwnProperty('currentServiceNum')) {
      setCurrentServiceNum(JSON.parse(localStorage.getItem('currentServiceNum')));
      localStorage.removeItem('currentServiceNum');
    }
    if (localStorage.hasOwnProperty('currentOptionNum')) {
      setCurrentOptionNum(JSON.parse(localStorage.getItem('currentOptionNum')));
      localStorage.removeItem('currentOptionNum');
    }
    if (localStorage.hasOwnProperty('optionCountList')) {
      setOptionCountList(JSON.parse(localStorage.getItem('optionCountList')));
      localStorage.removeItem('optionCountList');
    }
    if (localStorage.hasOwnProperty('copyCountList')) {
      setCopyCountList(JSON.parse(localStorage.getItem('copyCountList')));
      localStorage.removeItem('copyCountList');
    }

    return photography;
  };

  const fetchPhotography = async (callback) => {
    console.log('fetchPhotography');
    // 撮影情報を取得
    try {
      let data = await API.graphql({
        query: getPhotography,
        variables: { id: photographyID }
      });
      console.log(data.data.getPhotography);
      renderUsePhotography = data.data.getPhotography;
      setPhotography(renderUsePhotography);

      callback(renderUsePhotography);
      
    } catch (err) {
        console.error('error:', err);
    }

    return;
  }

  const fetchPhotos = async () => {
    console.log('fetchPhotos');
    // 写真のリストを取得
    try {
      console.log(photographyID)
      const filter = {
        photographyID: {
          'eq': photographyID
        }
      }
      const fetchPhotos = await API.graphql(
        graphqlOperation(listPhotos, {filter: filter, limit: 10000})
      );
      // 初回データを保持
      var allPhotos = fetchPhotos.data.listPhotos.items;
      console.log(fetchPhotos);
      // 続きを取得
      var nextToken = fetchPhotos.data.listPhotos.nextToken;
      while (nextToken != null) {
        const nextPageQuery = await API.graphql(
          graphqlOperation(listPhotos, { filter: filter, limit: 10000, nextToken })
        );
        console.log(nextPageQuery);
        const nextPageData = nextPageQuery.data;
        allPhotos = allPhotos.concat(nextPageData.listPhotos.items);
        nextToken = nextPageData.listPhotos.nextToken;
      }

      // 取得した photo をソート
      var array = allPhotos.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });

      photoAndCheckedList.length = 0;

      const newPhotoAndCheckedList = [...photoAndCheckedList];
      array.forEach( (item) => {
        Storage.get(item.s3path)
        .then(result => {
          if (result) {
            newPhotoAndCheckedList.push({photo : item, checked : false});
          }
          setPhotoAndCheckedList(newPhotoAndCheckedList);
        })
        .catch(err => {
          console.log(err)
          });
        });
      // photos を設定
      // setPhotos(allPhotos.data.listPhotos.items)

    } catch (err) {
      console.error('error:', err);
    }
  }

  const generatePhotographySteps = (argPhotography) => {
    console.log('generatePhotographySteps');
    var tmpSteps = [];
    // コースの判定
    let photographyType = PHOTOGRAPHY_INFO[argPhotography.title];
    let course = photographyType.courses.find((item) => {
      return item.name == argPhotography.course;
    });

    // コース内にカバー写真が必要か
    if (course.counts[0] > 0) {
      tmpSteps.push(STRINGS.SELECT_PHOTO_TYPE_NAME_COVER);
    }
    // コース内に大写真が必要か
    if (course.counts[1] > 0) {
      tmpSteps.push(STRINGS.SELECT_PHOTO_TYPE_NAME_BIG);
    }
    // コース内に小写真が必要か
    if (course.counts[2] > 0) {
      tmpSteps.push(STRINGS.SELECT_PHOTO_TYPE_NAME_SMALL);
    }
    // コース内に中表紙写真が必要か
    if (course.counts[3] > 0) {
      tmpSteps.push(STRINGS.SELECT_PHOTO_TYPE_NAME_INSIDE);
    }
    // 撮影種別固有のものがあれば追加
    if (argPhotography.isUniqueItem) {
      tmpSteps.push(STRINGS.SELECT_PHOTO_TYPE_NAME_UNIQUE);
    }
    // サービスがあれば追加
    if (argPhotography.selectServicePhotoTypes.length > 0) {
      tmpSteps.push(STRINGS.SELECT_PHOTO_TYPE_NAME_SERVICE);
    }
    // サンプル利用プレゼントがあれば追加
    if (argPhotography.selectSamplePresent != "") {
      tmpSteps.push(STRINGS.SELECT_PHOTO_TYPE_NAME_PRESENT);
    }
    // オプション、焼き増しは必要なので無条件に追加
    tmpSteps.push(STRINGS.SELECT_PHOTO_TYPE_NAME_OPTION,
                  STRINGS.SELECT_PHOTO_TYPE_NAME_COPY);
    console.log(tmpSteps);

    renderUseSteps = tmpSteps;
    setSteps(tmpSteps);
    return tmpSteps;
  }

  const sortCompareFn = (a, b) => {
    if (a.checked && b.checked) {
      return a.name > b.name ? -1 : 1;
    }
    if (a.checked) return -1;
    
    return a.name > b.name ? -1 : 1;
  }

  const changeInitialState = (argPhotography, argSteps) => {  
    setSelectState(PHOTO_SELECT_STATE.IDLE);
    setActiveStep(0);
  }

  const changeCoverMode = (course, argSteps) => {
    console.log('CHANGE STATE: COVER');
    setSelectDescriptionString(STRINGS.SELECT_PHOTO_DESCRIPTION_COVER);

    // 表紙の写真のチェックを設定
    const newPhotoAndCheckedList = [...photoAndCheckedList];
    let selectedPhotoCount = 0;
    newPhotoAndCheckedList.map((photoAndChecked) => {
      let photo = photoAndChecked.photo;
      if (photo.isCoverPhoto) {
        photoAndChecked.checked = true;
        selectedPhotoCount++;
      } else {
        photoAndChecked.checked = false;
      }
    });
    var array = newPhotoAndCheckedList.sort(sortCompareFn);
    setPhotoAndCheckedList(array);

    setActiveStep(argSteps.indexOf(STRINGS.SELECT_PHOTO_TYPE_NAME_COVER));
    setSelectState(PHOTO_SELECT_STATE.COVER);
    setIsNegativeBtnVisible(false);
    setIsDoneString(false);
    let selectedCount = course.counts[0] - selectedPhotoCount;
    setRemainingNumber(selectedCount);
    setIsRemainingNumberDisp(true);
    setIsOptionResetButtonDisp(false);
    setIsNextBtnEnable(selectedCount == 0);
  }

  const changeBigMode = (course, argSteps) => {
    console.log('CHANGE STATE: BIG');
    setSelectDescriptionString(STRINGS.SELECT_PHOTO_DESCRIPTION_BIG);

    // 大の写真のチェックを設定
    const newPhotoAndCheckedList = [...photoAndCheckedList];
    let selectedPhotoCount = 0;
    newPhotoAndCheckedList.map((photoAndChecked) => {
      let photo = photoAndChecked.photo;
      if (photo.isBigPhoto) {
        photoAndChecked.checked = true;
        selectedPhotoCount++;
      } else {
        photoAndChecked.checked = false;
      }
    });    
    var array = newPhotoAndCheckedList.sort(sortCompareFn);
    setPhotoAndCheckedList(array);

    setActiveStep(argSteps.indexOf(STRINGS.SELECT_PHOTO_TYPE_NAME_BIG));
    setSelectState(PHOTO_SELECT_STATE.BIG);
    setIsNegativeBtnVisible(steps[0]!=STRINGS.SELECT_PHOTO_TYPE_NAME_BIG);
    setIsDoneString(false);
    let selectedCount = course.counts[1] - selectedPhotoCount;
    setRemainingNumber(selectedCount);
    setIsRemainingNumberDisp(true);
    setIsOptionResetButtonDisp(false);
    setIsNextBtnEnable(selectedCount == 0);
  }

  const changeSmallMode = (course, argSteps) => {
    console.log('CHANGE STATE: SMALL');
    setSelectDescriptionString(STRINGS.SELECT_PHOTO_DESCRIPTION_SMALL);

    // 小の写真のチェックを設定
    const newPhotoAndCheckedList = [...photoAndCheckedList];
    let selectedPhotoCount = 0;
    newPhotoAndCheckedList.map((photoAndChecked) => {
      let photo = photoAndChecked.photo;
      if (photo.isSmallPhoto) {
        photoAndChecked.checked = true;
        selectedPhotoCount++;
      } else {
        photoAndChecked.checked = false;
      }
    });
    var array = newPhotoAndCheckedList.sort(sortCompareFn);
    setPhotoAndCheckedList(array);

    setActiveStep(argSteps.indexOf(STRINGS.SELECT_PHOTO_TYPE_NAME_SMALL));
    setSelectState(PHOTO_SELECT_STATE.SMALL);
    setIsNegativeBtnVisible(steps[0]!=STRINGS.SELECT_PHOTO_TYPE_NAME_SMALL);
    setIsDoneString(false);
    let selectedCount = course.counts[2] - selectedPhotoCount;
    setRemainingNumber(selectedCount);
    setIsRemainingNumberDisp(true);
    setIsOptionResetButtonDisp(false);
    setIsNextBtnEnable(selectedCount == 0);
  };

  const changeInsideMode = (course, argSteps) => {
    console.log('CHANGE STATE: INSIDE');
    setSelectDescriptionString(STRINGS.SELECT_PHOTO_DESCRIPTION_INSIDE);

    // 小の写真のチェックを設定
    const newPhotoAndCheckedList = [...photoAndCheckedList];
    let selectedPhotoCount = 0;
    newPhotoAndCheckedList.map((photoAndChecked) => {
      let photo = photoAndChecked.photo;
      if (photo.isInsideCoverPhoto) {
        photoAndChecked.checked = true;
        selectedPhotoCount++;
      } else {
        photoAndChecked.checked = false;
      }
    });
    var array = newPhotoAndCheckedList.sort(sortCompareFn);
    setPhotoAndCheckedList(array);

    setActiveStep(argSteps.indexOf(STRINGS.SELECT_PHOTO_TYPE_NAME_INSIDE));
    setSelectState(PHOTO_SELECT_STATE.INSIDE);
    setIsNegativeBtnVisible(steps[0]!=STRINGS.SELECT_PHOTO_TYPE_NAME_INSIDE);
    setIsDoneString(false);
    let selectedCount = course.counts[3] - selectedPhotoCount;
    setRemainingNumber(selectedCount);
    setIsRemainingNumberDisp(true);
    setIsOptionResetButtonDisp(false);
    setIsNextBtnEnable(selectedCount == 0);
  };

  const changeUniqueMode = (photographyType, argSteps) => {
    console.log('CHANGE STATE: UNIQUE');

    if (photographyType == TYPE_HYAKUNICHI) {
      setSelectDescriptionString(STRINGS.SELECT_PHOTO_DESCRIPTION_UNIQUE_HYAKUNICHI);
    } else {
      setSelectDescriptionString('null');
    }

    // 小の写真のチェックを設定
    const newPhotoAndCheckedList = [...photoAndCheckedList];
    let selectedPhotoCount = 0;
    newPhotoAndCheckedList.map((photoAndChecked) => {
      let photo = photoAndChecked.photo;
      if (photo.isUniqueItemPhoto) {
        photoAndChecked.checked = true;
        selectedPhotoCount++;
      } else {
        photoAndChecked.checked = false;
      }
    });
    var array = newPhotoAndCheckedList.sort(sortCompareFn);
    setPhotoAndCheckedList(array);

    setActiveStep(argSteps.indexOf(STRINGS.SELECT_PHOTO_TYPE_NAME_UNIQUE));
    setSelectState(PHOTO_SELECT_STATE.UNIQUE);
    setIsNegativeBtnVisible(steps[0]!=STRINGS.SELECT_PHOTO_TYPE_NAME_UNIQUE);
    setIsDoneString(false);
    let selectedCount = 1 - selectedPhotoCount; // 固有のもので複数だったら検討。現状は百日手形足形記念額のみなので固定
    setRemainingNumber(selectedCount);
    setIsRemainingNumberDisp(true);
    setIsOptionResetButtonDisp(false);
    setIsNextBtnEnable(selectedCount == 0);
  };

  const changeServiceMode = (services, argSteps) => {
    console.log('CHANGE STATE: SERVICE');
    console.log(services)

    let tmpCurrentNum = currentServiceNum;
    let service = SERVICE_INFO[services[tmpCurrentNum].split('&')[0]];
    if (services.length > 1) {
      setSelectDescriptionString(STRINGS.SELECT_PHOTO_DESCRIPTION_SERVICE
        .replace('service', service.name) + ' (' + 1 + '/' + services.length + ')');
    } else {
      setSelectDescriptionString(STRINGS.SELECT_PHOTO_DESCRIPTION_SERVICE
        .replace('service', service.name))
    }

    // サービスの写真のチェックを設定
    const newPhotoAndCheckedList = [...photoAndCheckedList];
    let selectedPhotoCount = 0;
    newPhotoAndCheckedList.map((photoAndChecked) => {
      let photo = photoAndChecked.photo;
      if (photo.servicePhotoTypes.includes(services[tmpCurrentNum])) {
        photoAndChecked.checked = true;
        selectedPhotoCount++;
      } else {
        photoAndChecked.checked = false;
      }
    });
    var array = newPhotoAndCheckedList.sort(sortCompareFn);
    setPhotoAndCheckedList(array);
    
    setActiveStep(argSteps.indexOf(STRINGS.SELECT_PHOTO_TYPE_NAME_SERVICE));
    setSelectState(PHOTO_SELECT_STATE.SERVICE);
    setIsNegativeBtnVisible(true);
    setIsDoneString(false);
    // 選択されているサービスから枚数を判定
    let selectedCount = service.count - selectedPhotoCount;
    setRemainingNumber(selectedCount);
    setIsRemainingNumberDisp(true);
    setIsOptionResetButtonDisp(false);
    setIsNextBtnEnable(selectedCount == 0);
  };

  const changeSamplePresentMode = (present, argSteps) => {
    console.log('CHANGE STATE: PRESENT');
    setSelectDescriptionString(STRINGS.SELECT_PHOTO_DESCRIPTION_PRESENT
      .replace("present", SAMPLE_PRESENT_INFO[present].name));

    // サンプルプレゼントの写真のチェックを設定
    const newPhotoAndCheckedList = [...photoAndCheckedList];
    let selectedPhotoCount = 0;
    newPhotoAndCheckedList.map((photoAndChecked) => {
      let photo = photoAndChecked.photo;
      if (photo.isSamplePresentPhoto) {
        photoAndChecked.checked = true;
        selectedPhotoCount++;
      } else {
        photoAndChecked.checked = false;
      }
    });
    var array = newPhotoAndCheckedList.sort(sortCompareFn);
    setPhotoAndCheckedList(array);
    
    setActiveStep(argSteps.indexOf(STRINGS.SELECT_PHOTO_TYPE_NAME_PRESENT));
    setSelectState(PHOTO_SELECT_STATE.PRESENT);
    setIsNegativeBtnVisible(true);
    setIsDoneString(false);
    // 選択されているプレゼントから枚数を判定
    let selectedCount = SAMPLE_PRESENT_INFO[present].count - selectedPhotoCount;
    setRemainingNumber(selectedCount);
    setIsRemainingNumberDisp(true);
    setIsOptionResetButtonDisp(false);
    setIsNextBtnEnable(selectedCount == 0);
  };

  const changeOptionMode = (options, argSteps) => {
    console.log('CHANGE STATE: OPTION');
    let tmpCurrentNum = currentOptionNum;
    const newPhotoAndCheckedList = [...photoAndCheckedList];
    let selectedPhotoCount = 0;
    let selectedCount = 0;

    if (options.length > 0) {
      let option = OPTION_INFO[options[tmpCurrentNum].split('&')[0]];
      // オプションの写真のチェックを設定
      newPhotoAndCheckedList.map((photoAndChecked) => {
        let photo = photoAndChecked.photo;
        if (photo.optionPhotoTypes.includes(options[tmpCurrentNum])) {
          photoAndChecked.checked = true;
          selectedPhotoCount++;
        } else {
          photoAndChecked.checked = false;
        }
      });
      var array = newPhotoAndCheckedList.sort(sortCompareFn);
      setPhotoAndCheckedList(array);
  
      selectedCount = option.count - selectedPhotoCount;
      if (options.length > 1) {
        setSelectDescriptionString(STRINGS.SELECT_PHOTO_DESCRIPTION_OPTION
          .replace('option', option.name) + ' (' + (tmpCurrentNum+1) + '/' + options.length + ')');
      } else {
        setSelectDescriptionString(STRINGS.SELECT_PHOTO_DESCRIPTION_OPTION.replace('option', option.name));
      }
    } else {
      const newPhotoAndCheckedList = [...photoAndCheckedList];
      newPhotoAndCheckedList.map((photoAndChecked) => {
        photoAndChecked.checked = false;
      });
      setSelectDescriptionString(STRINGS.SELECT_PHOTO_DESCRIPTION_OPTION);
      // オプションが設定されていないのでダイアログを表示する
      setIsOptionSelectDialogOpen(true);   
    }
    var array = newPhotoAndCheckedList.sort(sortCompareFn);
    setPhotoAndCheckedList(array);
    
    setActiveStep(argSteps.indexOf(STRINGS.SELECT_PHOTO_TYPE_NAME_OPTION));
    setSelectState(PHOTO_SELECT_STATE.OPTION);
    setIsNegativeBtnVisible(true);
    setIsDoneString(false);
    // 選択されているサービスから枚数を判定
    setRemainingNumber(selectedCount);
    setIsRemainingNumberDisp(true);
    setIsOptionResetButtonDisp(true);
    setIsNextBtnEnable(selectedCount == 0);
  }; 

  const changeCopyMode = (argSteps) => {
    console.log('CHANGE STATE: COPY');
    setSelectDescriptionString(STRINGS.SELECT_PHOTO_DESCRIPTION_COPY);

    // チェックをリセット
    if ( photoAndCheckedList.length > 0 ) {
      // 確認画面から戻ってきた際には photoAndCheckedList がまだ設定されていないので更新しない
      const newPhotoAndCheckedList = [...photoAndCheckedList];
      const tmpPhotos = [];
      newPhotoAndCheckedList.map((photoAndChecked) => {
        let photo = photoAndChecked.photo;
        if (photo.isCopyPhoto) {
          photoAndChecked.checked = true;
        } else {
          photoAndChecked.checked = false;
        }
        tmpPhotos.push(photo);
      });
      var array = newPhotoAndCheckedList.sort(sortCompareFn);
      setPhotoAndCheckedList(array);
    }

    setIsCopyInfoDialogOpen(true);

    setActiveStep(argSteps.indexOf(STRINGS.SELECT_PHOTO_TYPE_NAME_COPY));
    setSelectState(PHOTO_SELECT_STATE.COPY);
    setIsNegativeBtnVisible(true);
    setIsDoneString(true);
    // setRemainingNumber(10);
    setIsRemainingNumberDisp(false);
    setIsOptionResetButtonDisp(false);
    setIsNextBtnEnable(true);
  };
  
  const changeNextState = (argPhotography, argSteps) => {  
    console.log('changeNextState');
    // コースの判定
    let photographyType = PHOTOGRAPHY_INFO[argPhotography.title];
    let course = photographyType.courses.find((item) => {
      return item.name == argPhotography.course;
    });
    console.log(argPhotography);

    // 現在が IDLE 以下で表紙が必要なら COVER へ遷移
    if (selectState <= PHOTO_SELECT_STATE.IDLE && argSteps.includes(STRINGS.SELECT_PHOTO_TYPE_NAME_COVER)) {
      // COVER モードへ変更
      changeCoverMode(course, argSteps);
      return;
    }

    // 現在が COVER 以下で大が必要なら BIG へ遷移
    if (selectState <= PHOTO_SELECT_STATE.COVER && argSteps.includes(STRINGS.SELECT_PHOTO_TYPE_NAME_BIG)) {
      // BIG モードへ変更
      changeBigMode(course, argSteps);
      return;
    }

    // 現在が BIG 以下で小が必要なら SMALL へ遷移
    if (selectState <= PHOTO_SELECT_STATE.BIG && argSteps.includes(STRINGS.SELECT_PHOTO_TYPE_NAME_SMALL)) {
      // SMALL モードへ変更
      changeSmallMode(course, argSteps);
      return;
    }

    // 現在が SMALL 以下で中表紙が必要なら INSIDE へ遷移
    if (selectState <= PHOTO_SELECT_STATE.SMALL && argSteps.includes(STRINGS.SELECT_PHOTO_TYPE_NAME_INSIDE)) {
      // INSIDE モードへ変更
      changeInsideMode(course, argSteps);
      return;
    }

    // 現在が INSIDE なら UNIQUE / SERVICE / PRESENT / OPTION のいずれかへ遷移
    if (selectState <= PHOTO_SELECT_STATE.INSIDE) {
      // 撮影種別固有のものがあれば遷移
      if (argPhotography.isUniqueItem) {
        changeUniqueMode(argPhotography.title, argSteps);
        return;
      } 
      // サービスがあれば SERVICE モードへ変更
      if (argPhotography.selectServicePhotoTypes.length > 0 ) {
        console.log(argPhotography.selectServicePhotoTypes);
        changeServiceMode(argPhotography.selectServicePhotoTypes, argSteps);
        return;
      }
      // プレゼントがあれば PRESENT へモード変更
      if (photography.selectSamplePresent != '') {
        changeSamplePresentMode(photography.selectSamplePresent, argSteps);
        return;
      }
      // OPTION モードへ変更
      changeOptionMode(photography.selectOptionPhotoTypes, argSteps);
      return;
    }

    // 現在が UNIQUE なら SERVICE / PRESENT / OPTION のいずれかへ遷移
    if (selectState <= PHOTO_SELECT_STATE.UNIQUE) {
      // サービスがあれば SERVICE モードへ変更
      if (argPhotography.selectServicePhotoTypes.length > 0 ) {
        console.log(argPhotography.selectServicePhotoTypes);
        changeServiceMode(argPhotography.selectServicePhotoTypes, argSteps);
        return;
      }
      // プレゼントがあれば PRESENT へモード変更
      if (photography.selectSamplePresent != '') {
        changeSamplePresentMode(photography.selectSamplePresent, argSteps);
        return;
      }
      // OPTION モードへ変更
      changeOptionMode(photography.selectOptionPhotoTypes, argSteps);
      return;
    }

    // 現在が SERVICE ならサンプル利用許可があれば PRESENT そうでなければ OPTION へ遷移
    if (selectState <= PHOTO_SELECT_STATE.SERVICE) {
      console.log(photography.selectSamplePresent);
      if (photography.selectSamplePresent != '') {
        changeSamplePresentMode(photography.selectSamplePresent, argSteps);
        return;
      }
      // OPTION モードへ変更
      changeOptionMode(photography.selectOptionPhotoTypes, argSteps);
      return;
    }

    // 現在が PRESENT なら COPY へ遷移
    if (selectState <= PHOTO_SELECT_STATE.PRESENT) {
      // OPTION モードへ変更
      changeOptionMode(photography.selectOptionPhotoTypes, argSteps);
      return;
    }

    // 現在が OPTION なら COPY へ遷移
    if (selectState <= PHOTO_SELECT_STATE.OPTION) {
      // COPY モードへ変更
      changeCopyMode(argSteps);
      return;
    }
  }

  const changeBackState = (argPhotography, argSteps) => {  
    console.log('changeBackState: ' + selectState);
    // コースの判定
    let photographyType = PHOTOGRAPHY_INFO[argPhotography.title];
    let course = photographyType.courses.find((item) => {
      return item.name == argPhotography.course;
    });
    console.log(course);

    // チェックボックスをリセット
    var checkCount = 0
    console.log(checkCount);

    // 現在が COPY なら OPTION へ遷移
    if (selectState >= PHOTO_SELECT_STATE.COPY) {
      // OPTION モードへ変更
      changeOptionMode(photography.selectOptionPhotoTypes, argSteps);
      return;
    }

    // 現在が OPTION なら PRESENT/SERVICE へ遷移
    if (selectState >= PHOTO_SELECT_STATE.OPTION) {
      if (argSteps.includes(STRINGS.SELECT_PHOTO_TYPE_NAME_PRESENT)) {
        // サンプル許可プレゼントが必要なら PRESENT モードへ変更
        changeSamplePresentMode(photography.selectSamplePresent, argSteps);
      } else if (argPhotography.selectServicePhotoTypes.length > 0 ) {
        // SERVICE モードへ変更
        changeServiceMode(argPhotography.selectServicePhotoTypes, argSteps);
      } else if (argPhotography.isUniqueItem) {
        // 撮影種別固有のものが必要なら UNIQUE モードへ変更
        changeInsideMode(course, argSteps);
      } else if (argSteps.includes(STRINGS.SELECT_PHOTO_TYPE_NAME_INSIDE)) {
        // 中表紙が必要なら INSIDE モードへ変更
        changeInsideMode(course, argSteps);
      } else if (argSteps.includes(STRINGS.SELECT_PHOTO_TYPE_NAME_SMALL)) {
        // 小が必要なら SMALL モードへ変更
        changeSmallMode(course, argSteps);
      } else if (argSteps.includes(STRINGS.SELECT_PHOTO_TYPE_NAME_BIG)) {
        // 大が必要なら BIG モードへ変更
        changeBigMode(course, argSteps);
      } else if (argSteps.includes(STRINGS.SELECT_PHOTO_TYPE_NAME_COVER)) {
        // 表紙が必要なら COVER モードへ変更
        changeCoverMode(course, argSteps);
      }      

    }

    // 現在が PRESENT なら SERVICE へ遷移
    if (selectState >= PHOTO_SELECT_STATE.PRESENT) {
      // SERVICE モードへ変更
      // サービスがあれば SERVICE モードへ変更
      if (argPhotography.selectServicePhotoTypes.length > 0 ) {
        console.log(argPhotography.selectServicePhotoTypes);
        changeServiceMode(argPhotography.selectServicePhotoTypes, argSteps);
        return;
      }
    }

    // 現在が SERVICE なら UNIQUE/INSIDE/SMALL/BIG/COVER の必要なものへ遷移
    if (selectState >= PHOTO_SELECT_STATE.SERVICE) {
      if (argPhotography.isUniqueItem) {
        // 撮影種別固有のものが必要なら UNIQUE モードへ変更
        changeUniqueMode(argPhotography.title, argSteps);
      } else if (argSteps.includes(STRINGS.SELECT_PHOTO_TYPE_NAME_INSIDE)) {
        // 中表紙が必要なら INSIDE モードへ変更
        changeInsideMode(course, argSteps);
      } else if (argSteps.includes(STRINGS.SELECT_PHOTO_TYPE_NAME_SMALL)) {
        // 小が必要なら SMALL モードへ変更
        changeSmallMode(course, argSteps);
      } else if (argSteps.includes(STRINGS.SELECT_PHOTO_TYPE_NAME_BIG)) {
        // 大が必要なら BIG モードへ変更
        changeBigMode(course, argSteps);
      } else if (argSteps.includes(STRINGS.SELECT_PHOTO_TYPE_NAME_COVER)) {
        // 表紙が必要なら COVER モードへ変更
        changeCoverMode(course, argSteps);
      }      
      return;
    }

      // 現在が UNIQUE なら INSIDE/SMALL/BIG/COVER の必要なものへ遷移
      if (selectState >= PHOTO_SELECT_STATE.UNIQUE) {
        if (argSteps.includes(STRINGS.SELECT_PHOTO_TYPE_NAME_INSIDE)) {
          // 中表紙が必要なら INSIDE モードへ変更
          changeInsideMode(course, argSteps);
        } else if (argSteps.includes(STRINGS.SELECT_PHOTO_TYPE_NAME_SMALL)) {
          // 小が必要なら SMALL モードへ変更
          changeSmallMode(course, argSteps);
        } else if (argSteps.includes(STRINGS.SELECT_PHOTO_TYPE_NAME_BIG)) {
          // 大が必要なら BIG モードへ変更
          changeBigMode(course, argSteps);
        } else if (argSteps.includes(STRINGS.SELECT_PHOTO_TYPE_NAME_COVER)) {
          // 表紙が必要なら COVER モードへ変更
          changeCoverMode(course, argSteps);
        }      
        return;
      }
  
    // 現在が INSIDE なら SMALL/BIG/COVER の必要なものへ遷移
    if (selectState >= PHOTO_SELECT_STATE.INSIDE) {
      if (argSteps.includes(STRINGS.SELECT_PHOTO_TYPE_NAME_SMALL)) {
        // 小が必要なら SMALL モードへ変更
        changeSmallMode(course, argSteps);
      } else if (argSteps.includes(STRINGS.SELECT_PHOTO_TYPE_NAME_BIG)) {
        // 大が必要なら BIG モードへ変更
        changeBigMode(course, argSteps);
      } else if (argSteps.includes(STRINGS.SELECT_PHOTO_TYPE_NAME_COVER)) {
        // 表紙が必要なら COVER モードへ変更
        changeCoverMode(course, argSteps);
      }      
      return;
    }

    // 現在が SMALL なら BIG/COVER の必要なものへ遷移
    if (selectState >= PHOTO_SELECT_STATE.SMALL) {
      if (argSteps.includes(STRINGS.SELECT_PHOTO_TYPE_NAME_BIG)) {
        // 大が必要なら BIG モードへ変更
        changeBigMode(course, argSteps);
      } else if (argSteps.includes(STRINGS.SELECT_PHOTO_TYPE_NAME_COVER)) {
        // 表紙が必要なら COVER モードへ変更
        changeCoverMode(course, argSteps);
      }      
      return;
    }

    // 現在が BIG で COVER が必要であれば COVER へ遷移
    if (selectState >= PHOTO_SELECT_STATE.BIG) {
      if (argSteps.includes(STRINGS.SELECT_PHOTO_TYPE_NAME_COVER)) {
        // 表紙が必要なら COVER モードへ変更
        changeCoverMode(course, argSteps);
      }      
      return;
    }
  }

  const resetSelectOptionPhoto = () => {
    photography.selectOptionPhotoTypes = [];

    const newPhotoAndCheckedList = [...photoAndCheckedList];
    newPhotoAndCheckedList.map((photoAndChecked) => {
      let photo = photoAndChecked.photo;
      photo.optionPhotoTypes.length = 0;
    });
    setPhotoAndCheckedList(newPhotoAndCheckedList);

    setOptionCountList(Array.from({ length: Object.keys(OPTION_INFO).length }, () => 0));
    setIsOptionDecideBtnEnable(false);
  }

  const handleOptionDialogChange = (value, key) => {
    // 数量変更
    if (value < 0 ) {
      value = 0;
    }
    const newOptionCountList = [...optionCountList];
    newOptionCountList[OPTION_INFO[key].index] = value;
    setOptionCountList(newOptionCountList);

    // なにか一つ選ばないと決定できない
    let total = newOptionCountList.reduce(function(sum, element){
      return sum + element;
    }, 0);
    setOptionCountList(newOptionCountList);
    setIsOptionDecideBtnEnable(total > 0);
  }

  const handleOptionDialogDecide = (optionSelectedResult) => {
    setIsOptionSelectDialogOpen(false);
    // オプションはクリアしておく
    resetSelectOptionPhoto();

    optionSelectedResult.map( (option)  => {
      photography.selectOptionPhotoTypes.push(option.type + '&' + option.id);
    });

    // オプションが設定されたので残り選択枚数を再設定
    var key = optionSelectedResult[0].type;
    setRemainingNumber(OPTION_INFO[key].count);
    if (photography.selectOptionPhotoTypes.length > 1) {
      setSelectDescriptionString(STRINGS.SELECT_PHOTO_DESCRIPTION_OPTION
        .replace('option', OPTION_INFO[key].name) + ' (' + 1 + '/' + photography.selectOptionPhotoTypes.length + ')');
    } else {
      setSelectDescriptionString(STRINGS.SELECT_PHOTO_DESCRIPTION_OPTION.replace('option', OPTION_INFO[key].name));
    }
    setIsNextBtnEnable(false);
  };

  const handleOptionDialogSkip = () => {
    setIsOptionSelectDialogOpen(false);
    // オプションはクリアしておく
    resetSelectOptionPhoto();

    changeNextState(photography, steps);
  };

  const handleOptionDialogBack = () => {
    setIsOptionSelectDialogOpen(false);
    changeBackState(photography, steps);
  }

  const handleCopyCountChanged = (photoIndex, photo, key, value) => {
    // copyType がない場合は配列入れておく
    if (photo.copyTypes == null) {
      photo.copyTypes =[];
    }

    // copyType を取得
    let copyType = photo.copyTypes.find((item) => {
      return item.key == key;
    });
    if (copyType == undefined){
      // photo に copyType が存在しなかった
      copyType = {key: key, count: value};
      photo.copyTypes.push(copyType);
    } else {
      // photo に copyType が存在した
      copyType.count = value;
    }
    setCopySelectPhoto(photo);
    // 数量変更
    const newCopyCountList = [...copyCountList];
    newCopyCountList[COPY_INFO[key].index] = value;
    setCopyCountList(newCopyCountList);

    let total = newCopyCountList.reduce(function(sum, element){
      return sum + element;
    }, 0);
    setCopyDialogDoneBtnEnable(total > 0);
  }

  const handleCopyDialogOK = (index) => {
    setIsCopySelectDialogOpen(false);
  }

  const handleCopyDialogCancel = (index) => {
    photoAndCheckedList[index].checked = false;
    photoAndCheckedList[index].photo.isCopyPhoto = false;
    setIsCopySelectDialogOpen(false);
  }

  const handleCopyInfoDialogClose = () => {
    setIsCopyInfoDialogOpen(false);
  }

  const handleImageClick = async (photo) => {
    let resultUrl = '';
    await Storage.get(photo.s3path)
    .then(result => {
      if (result) {
        resultUrl = result;
      }
    })
    .catch(err => {
      console.log(err)
    });
    setImageViewerUrl(resultUrl);
    setIsPhotoZoomModalOpen(true);
  };

  const handleImageClose = (index) => {
    setIsPhotoZoomModalOpen(false);
  };

  const handlePhotoCheckChange = (event, index) => {
    let checkStatus = event.target.checked;
    let tmpCheckedCount = remainingNumber;
    let photo = photoAndCheckedList[index].photo;

    const newPhotoAndCheckedList = [...photoAndCheckedList];
    newPhotoAndCheckedList[index].checked = checkStatus;
    changePhotoSelectStatus(checkStatus, index, photo);

    setTimeout(() => {
      var array = newPhotoAndCheckedList.sort(sortCompareFn);
      setPhotoAndCheckedList(array);
      }, 1000);
    // startTransition(() => {
    //   var array = newPhotoAndCheckedList.sort(sortCompareFn);
    //   setPhotoAndCheckedList(array);
    //   });

    // 残り枚数の設定
    if (checkStatus) {
      tmpCheckedCount--;
      setRemainingNumber(tmpCheckedCount);
    } else {
      tmpCheckedCount++;
      setRemainingNumber(tmpCheckedCount);
    }

    if (selectState != PHOTO_SELECT_STATE.COPY) {
      // 次へボタンの活性/非活性
      if (tmpCheckedCount == 0) {
        setIsNextBtnEnable(true);
      } else {
        setIsNextBtnEnable(false);
      }
    }
  }

  const changePhotoSelectStatus = (status, index, photo) => {
    if (selectState == PHOTO_SELECT_STATE.COVER) {
      photo.isCoverPhoto = status;
    } else if (selectState == PHOTO_SELECT_STATE.BIG) {
      photo.isBigPhoto = status;
    } else if (selectState == PHOTO_SELECT_STATE.SMALL) {
      photo.isSmallPhoto = status;
    } else if (selectState == PHOTO_SELECT_STATE.INSIDE) {
      photo.isInsideCoverPhoto = status;
    } else if (selectState == PHOTO_SELECT_STATE.UNIQUE) {
      photo.isUniqueItemPhoto = status;
    } else if (selectState == PHOTO_SELECT_STATE.SERVICE) {
      photo.isServicePhoto = status;
      let serviceId = photography.selectServicePhotoTypes[currentServiceNum];
      if (status) {
        // チェックが付けられたら記憶しておく
        photo.servicePhotoTypes.push(serviceId);
      } else {
        // チェックが外れた場合には配列から除外
        var index = photo.servicePhotoTypes.indexOf(serviceId);
        photo.servicePhotoTypes.splice(index, 1)
      }
    } else if (selectState == PHOTO_SELECT_STATE.PRESENT) {
      photo.isSamplePresentPhoto = status;
    } else if (selectState == PHOTO_SELECT_STATE.OPTION) {
      photo.isOptionPhoto = status;
      let optionId = photography.selectOptionPhotoTypes[currentOptionNum];
      if (status) {
        // チェックが付けられたら記憶しておく
        photo.optionPhotoTypes.push(optionId);
      } else {
        // チェックが外れた場合には配列から除外
        var index = photo.optionPhotoTypes.indexOf(optionId);
        photo.optionPhotoTypes.splice(index, 1)
      }
    } else if (selectState == PHOTO_SELECT_STATE.COPY) {
      setCopySelectPhotoIndex(index);
      photo.isCopyPhoto = status;
      if (status) {
        setIsCopySelectDialogOpen(true);
        // 焼き増し枚数を設定
        if (photo.copyTypes == null) {
          photo.copyTypes =[];
        }

        // 数量変更
        const newCopyCountList = [...copyCountList];
        Object.keys(COPY_INFO).map((key) => {
          // copyType は COPY_INFO のキー
          let copyType = photo.copyTypes.find((item) => {
            return item.key == key;
          });
          if (copyType == undefined){
            // photo に copyType が存在しなかった
            newCopyCountList[COPY_INFO[key].index] = 0;
          } else {
            // photo に copyType が存在した
            newCopyCountList[COPY_INFO[key].index] = copyType.count;
          }
        });
        setCopyCountList(newCopyCountList);
    
        setCopySelectPhoto(photo);
      } else {
        setCopyCountList(Array.from({ length: Object.keys(COPY_INFO).length }, () => 0));
      }
    }
  }

  const handlePositiveButtonClick = () => {
    console.log(photoAndCheckedList);
    console.log(photography);

    // トップへスクロール
    var options = { smooth:true, duration:200 }
    scroll.scrollToTop(options);

    // 現在のステータスが最後の選択(焼き増し)の場合は確認画面を表示
    if (selectState == PHOTO_SELECT_STATE.COPY) {
      let photos = [];
      photoAndCheckedList.map((photoAndChecked) => {
        let photo = photoAndChecked.photo;
        photos.push(photo);
      });
      photography.photos = photos;
      savePhotoSelectLocalStorage();
      navigate(PATH.SELECT_CONFIRM, {state: {photography:photography, photos:photos}});
      return;
    }

    // 最後の選択になる際にはボタンの文言を変更する
    if (selectState == steps.slice(-2)[0]) {
      setIsDoneString(true);
    } else {
      setIsDoneString(false);
    }

    // サービスの場合は複数選択させる場合があるので、最後のサービスでなければもう一度選択させる
    if (selectState == PHOTO_SELECT_STATE.SERVICE) {
      let tmpCurrentNum = currentServiceNum + 1;
      if (photography.selectServicePhotoTypes.length > tmpCurrentNum) {
        // チェック状態を復元
        const newPhotoAndCheckedList = [...photoAndCheckedList];
        let selectedPhotoCount = 0;
        newPhotoAndCheckedList.map((photoAndChecked) => {
          let photo = photoAndChecked.photo;
          if (photo.servicePhotoTypes.includes(photography.selectServicePhotoTypes[tmpCurrentNum])) {
            photoAndChecked.checked = true;
            selectedPhotoCount++;
          } else {
            photoAndChecked.checked = false;
          }
        });
        var array = newPhotoAndCheckedList.sort(sortCompareFn);
        setPhotoAndCheckedList(array);
        
        let service = SERVICE_INFO[photography.selectServicePhotoTypes[tmpCurrentNum].split('&')[0]];
        // 次のサービスの残り選択枚数を再設定
        let selectedCount = service.count - selectedPhotoCount;
        setRemainingNumber(selectedCount);
        setIsRemainingNumberDisp(true);
        setIsNextBtnEnable(selectedCount == 0);

        if (photography.selectServicePhotoTypes.length > 1) {
          setSelectDescriptionString(STRINGS.SELECT_PHOTO_DESCRIPTION_SERVICE
            .replace('service', service.name) + ' (' + (tmpCurrentNum+1) + '/' + photography.selectServicePhotoTypes.length + ')');
          } else {
          setSelectDescriptionString(STRINGS.SELECT_PHOTO_DESCRIPTION_SERVICE
            .replace('service', service.name))
        }
        setCurrentServiceNum(tmpCurrentNum);
        return;
      }
    }

    // オプションの選択の場合は複数選択させる必要があるので、最後のオプションでなければもう一度選択させる
    if (selectState == PHOTO_SELECT_STATE.OPTION) {
      let tmpCurrentNum = currentOptionNum + 1;
      if (photography.selectOptionPhotoTypes.length > tmpCurrentNum) {
        // チェック状態を復元
        const newPhotoAndCheckedList = [...photoAndCheckedList];
        let selectedPhotoCount = 0;
        newPhotoAndCheckedList.map((photoAndChecked) => {
          let photo = photoAndChecked.photo;
          if (photo.optionPhotoTypes.includes(photography.selectOptionPhotoTypes[tmpCurrentNum])) {
            photoAndChecked.checked = true;
            selectedPhotoCount++;
          } else {
            photoAndChecked.checked = false;
          }
        });
        var array = newPhotoAndCheckedList.sort(sortCompareFn);
        setPhotoAndCheckedList(array);

        // オプションが設定されたので残り選択枚数を再設定
        var key = photography.selectOptionPhotoTypes[tmpCurrentNum].split('&')[0];
        let selectedCount = OPTION_INFO[key].count - selectedPhotoCount;
        setRemainingNumber(selectedCount);
        setSelectDescriptionString(STRINGS.SELECT_PHOTO_DESCRIPTION_OPTION.replace('option', OPTION_INFO[key].name));
        setIsNextBtnEnable(selectedCount == 0);

        setCurrentOptionNum(tmpCurrentNum);
        return;
      }
    }

    changeNextState(photography, steps);
  };

  const handleNegativeButtonClick = () => {
    console.log(photoAndCheckedList);
    console.log(photography);

    // トップへスクロール
    var options = { smooth:true, duration:200 }
    scroll.scrollToTop(options);

    // サービスの場合は複数選択させる場合があるので、サービスを一つ戻す
    if (selectState == PHOTO_SELECT_STATE.SERVICE) {
      let tmpCurrentNum = currentServiceNum - 1;
      if (0 <= tmpCurrentNum) {
        // チェック状態を復元
        const newPhotoAndCheckedList = [...photoAndCheckedList];
        let selectedPhotoCount = 0;
        newPhotoAndCheckedList.map((photoAndChecked) => {
          let photo = photoAndChecked.photo;
          if (photo.servicePhotoTypes.includes(photography.selectServicePhotoTypes[tmpCurrentNum])) {
            photoAndChecked.checked = true;
            selectedPhotoCount++;
          } else {
            photoAndChecked.checked = false;
          }
        });
        setPhotoAndCheckedList(newPhotoAndCheckedList);
    
        let service = SERVICE_INFO[photography.selectServicePhotoTypes[tmpCurrentNum].split('&')[0]];
        // 次のサービスの残り選択枚数を再設定
        let selectedCount = service.count - selectedPhotoCount;
        setRemainingNumber(selectedCount);
        if (photography.selectServicePhotoTypes.length > 1) {
          setSelectDescriptionString(STRINGS.SELECT_PHOTO_DESCRIPTION_SERVICE
            .replace('service', service.name) + ' (' + (tmpCurrentNum+1) + '/' + photography.selectServicePhotoTypes.length + ')');
          } else {
          setSelectDescriptionString(STRINGS.SELECT_PHOTO_DESCRIPTION_SERVICE
            .replace('service', service.name))
        }
        setIsNextBtnEnable(selectedCount == 0);

        setCurrentServiceNum(tmpCurrentNum);
        return;
      }
    }

    // オプションの場合は複数選択させる場合があるので、オプションを一つ戻す
    if (selectState == PHOTO_SELECT_STATE.OPTION) {
      let tmpCurrentNum = currentOptionNum - 1;
      if (0 <= tmpCurrentNum) {
        // チェック状態を復元
        const newPhotoAndCheckedList = [...photoAndCheckedList];
        let selectedPhotoCount = 0;
        newPhotoAndCheckedList.map((photoAndChecked) => {
          let photo = photoAndChecked.photo;
          if (photo.optionPhotoTypes.includes(photography.selectOptionPhotoTypes[tmpCurrentNum])) {
            photoAndChecked.checked = true;
            selectedPhotoCount++;
          } else {
            photoAndChecked.checked = false;
          }
        });
        setPhotoAndCheckedList(newPhotoAndCheckedList);

        // オプションが設定されたので残り選択枚数を再設定
        var key = photography.selectOptionPhotoTypes[tmpCurrentNum].split('&')[0];
        let selectedCount = OPTION_INFO[key].count - selectedPhotoCount;
        setRemainingNumber(selectedCount);
        if (photography.selectOptionPhotoTypes.length > 1) {
          setSelectDescriptionString(STRINGS.SELECT_PHOTO_DESCRIPTION_OPTION
            .replace('option', OPTION_INFO[key].name) + ' (' + (tmpCurrentNum+1) + '/' + photography.selectOptionPhotoTypes.length + ')');
        } else {
          setSelectDescriptionString(STRINGS.SELECT_PHOTO_DESCRIPTION_OPTION.replace('option', OPTION_INFO[key].name));
        }
        setIsNextBtnEnable(selectedCount == 0);

        setCurrentOptionNum(tmpCurrentNum);
        return;
      }
    }

    changeBackState(photography, steps);
  }

  const handleOptionResetButtonClick = () => {
    resetSelectOptionPhoto();
    setIsOptionSelectDialogOpen(true);
  }
    
  return (
    <>
      <PhotoImageZoomModal
        isOpen={isPhotoZoomModalOpen}
        imageUrl={imageViewerUrl}
        onClose={handleImageClose}
      />
      <PhotographyOptionSelectDialog
          showDialog={isOptionSelectDialogOpen}
          optionCountList={optionCountList}
          isDecideBtnEnable={isOptionDecideBtnEnable}
          onChange={handleOptionDialogChange}
          onDecide={handleOptionDialogDecide}
          onSkip={handleOptionDialogSkip}
          onBack={handleOptionDialogBack} />

      <PhotoSelectCopyInfoDialog
          showDialog={isCopyInfoDialogOpen}
          onClose={handleCopyInfoDialogClose} />

      <PhotographyCopySelectDialog
          showDialog={isCopySelectDialogOpen}
          photo={copySelectPhoto}
          index={copySelectPhotoIndex}
          copyCountList={copyCountList}
          isDoneBtnEnable={isCopyDialogDoneBtnEnable}
          onCopyCountChanged={handleCopyCountChanged}
          onOK={handleCopyDialogOK}
          onCancel={handleCopyDialogCancel} />

      <Logo disabledLink />
      <Helmet>
        <title> 日光写真館 プリント | 撮影詳細 </title>
      </Helmet>

      <Grid container spacing={10} justifyContent="center" alignItems="center">
        <Grid item xs={12} style={{ marginTop: '16px', marginBottom: '16px' }}>
          <Typography variant="h5">{selectPhotograpyTitle}</Typography>
        </Grid>
      </Grid>

      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
            <Step key={label}>
                <StepLabel sx={{fontSize: 10}}></StepLabel>
            </Step>
        ))}
      </Stepper>

      <Container style={wapper}>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }} margin={2}>{selectDescriptionString}</Typography>

        <Grid container spacing={3} marginBottom={20}>
          {photoAndCheckedList.map((pac, index) => (
          <Grid item xs={6} sm={6} md={3} key={pac.photo.id}>
            <PhotoCard
                key={pac.photo.id}
                photo={pac.photo}
                index={index}
                isChecked={pac.checked}
                isSelectable={true}
                onClick={handleImageClick}
                onChanged={handlePhotoCheckChange} />
          </Grid>
          ))}
        </Grid>

        <SelectFooter
          remainingNumber={remainingNumber}
          isNegativeButtonVisible={isNegativeBtnVisible}
          isNextButtonEnable={isNextBtnEnable}
          isDoneButton={isDoneString}
          isRemainingNumberDisp={isRemainingNumberDisp}
          isOptionResetButtonDisp={isOptionResetButtonDisp}
          onPositiveButtonClick={handlePositiveButtonClick}
          onNegativeButtonClick={handleNegativeButtonClick}
          onOptionReset={handleOptionResetButtonClick} />

      </Container>
    </>
  );
}

