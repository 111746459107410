import * as React from 'react';
import PropTypes from 'prop-types';
// mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Grid, Typography, IconButton, TextField } from '@mui/material';
// other
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "../../utils/image-gallery.css";

PhotoSelectConfirmDialog.propTypes = {
  open: PropTypes.bool,
  onSelectOK: PropTypes.func,
  onClose: PropTypes.func
};

export default function PhotoSelectConfirmDialog({ open, onSelectOK, onClose }) {
  const [comment, setComment] = React.useState('');

  const handleSend = () => {
    onSelectOK(comment);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <ToastContainer />
      <Dialog open={open} onClose={onClose}>  
        <DialogTitle>プリントする写真を選択</DialogTitle>
        <Box position="absolute" top={0} right={0}>
          <IconButton>
          </IconButton>
        </Box>
        <DialogContent>
          <Typography>選択した写真をプリントします <br />
            ※送信後に写真を変更したい場合は日光写真館までご連絡ください<br />
            (送信後、ダウンロード可能です)<br /><br />
            </Typography>
          <TextField
              margin="normal"
              fullWidth={true}
              value={comment}
              multiline
              maxRows={5}
              placeholder='日光写真館へのご意見・ご要望があればご記入下さい'
              onChange={
                (event) => {
                  setComment(event.target.value);
                }
              }
            />

        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={handleClose}>
            キャンセル
          </Button>
          <Button color="secondary" variant="contained"
            onClick={() =>{
              handleSend(comment);
              }
            } >
            送信する
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}