import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
// amplify
import { API, graphqlOperation, Storage } from "aws-amplify";
import { listPhotos, getPhotography } from "../graphql/queries";
// @mui
import { Grid, Box, Container, Typography} from '@mui/material';
// components
import Logo from '../components/logo/Logo';
import { PhotoCard } from '../sections/@dashboard/photography';
import PhotoImageZoomModal from '../components/modal/PhotoImageZoomModal';
// other
import { PHOTOGRAPHY_INFO, SERVICE_INFO, SAMPLE_PRESENT_INFO, OPTION_INFO, COPY_INFO, TYPE_SIMPLE } from '../constants';
import { NikkoUtil } from '../utils';

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' },
];

// ----------------------------------------------------------------------

export default function PhotoSelectDonePage() {
  const { photographyID } = useParams();

  const [photography, setPhotography] = React.useState()
  const [photos, setPhotos] = React.useState([]);
  const [phogographyTitle, setPhogographyTitle] = React.useState('');
  const [uniqueTitle, setUniqueTitle] = React.useState('');
  const [sendCompleteStringDownload, setSendCompleteStringDownload] = React.useState(false);

  const [isPhotoZoomModalOpen, setIsPhotoZoomModalOpen] = React.useState(false);
  const [imageViewerUrl, setImageViewerUrl] = React.useState([]);

  const [selectedCoverPhotos, setSelectedCoverPhotos] = React.useState([]);
  const [selectedBigPhotos, setSelectedBigPhotos] = React.useState([]);
  const [selectedSmallPhotos, setSelectedSmallPhotos] = React.useState([]);
  const [selectedInsidePhotos, setSelectedInsidePhotos] = React.useState([]);
  const [selectedUniquePhotos, setSelectedUniquePhotos] = React.useState([]);
  const [selectedServicePhotos, setSelectedServicePhotos] = React.useState([]);
  const [selectedPresentPhotos, setSelectedPresentPhotos] = React.useState([]);
  const [selectedOptionPhotos, setSelectedOptionPhotos] = React.useState([]);
  const [selectedCopyPhotos, setSelectedCopyPhotos] = React.useState([]);

  React.useEffect(() => {
    fetchPhotography();
    fetchPhotos();
  }, [])

  const fetchPhotography = async () => {
    // 撮影情報を取得
    try {
      var photographyResult = await API.graphql({
        query: getPhotography,
        variables: { id: photographyID }
      });
      
      var fetchPhotography = photographyResult.data.getPhotography;
      setPhotography(fetchPhotography);
      // シンプルコースのポーズ0(ダウンロード用)の場合は文言を変更
      setSendCompleteStringDownload(fetchPhotography.title == TYPE_SIMPLE && fetchPhotography.course);        

      setUniqueTitle(NikkoUtil.getUniqueTitle(fetchPhotography.title));
    } catch (err) {
        console.error('error:', err);
    }
  }

  const fetchPhotos = async () => {
    // 写真のリストを取得
    try {
      console.log(photographyID)
      const filter = {
        photographyID: {
          'eq': photographyID,
        }
      }

      const fetchPhotos = await API.graphql(
        graphqlOperation(listPhotos, {filter: filter, limit: 10000})
      );
      // 初回データを保持
      var allPhotos = fetchPhotos.data.listPhotos.items;
      // 続きを取得
      var nextToken = fetchPhotos.data.listPhotos.nextToken;
      while (nextToken != null) {
        const nextPageQuery = await API.graphql(
          graphqlOperation(listPhotos, { filter: filter, limit: 10000, nextToken })
        );
        const nextPageData = nextPageQuery.data;
        allPhotos = allPhotos.concat(nextPageData.listPhotos.items);
        nextToken = nextPageData.listPhotos.nextToken;
      }

      var array = allPhotos.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });

      setPhotos(array)
      settingSelectedPhotos(array);
    } catch (err) {
      console.error('error:', err);
    }
  }

  const settingSelectedPhotos = (photoArray) => {
    // 撮影情報を取得
    let coverPhotos = [];
    let bigPhotos = [];
    let smallPhotos = [];
    let insidePhotos = [];
    let uniquePhotos = [];
    let servicePhotos = [];
    let presentPhotos = [];
    let optionPhotos = [];
    let copyPhotos = [];

    photoArray.map((photo) => {
      if (photo.isCoverPhoto) {
        coverPhotos.push(photo);
      }
      if (photo.isBigPhoto) {
        bigPhotos.push(photo);
      }
      if (photo.isSmallPhoto) {
        smallPhotos.push(photo);
      }
      if (photo.isInsideCoverPhoto) {
        insidePhotos.push(photo);
      }
      if (photo.isUniqueItemPhoto) {
        uniquePhotos.push(photo);
      }
      if (photo.servicePhotoTypes.length > 0) {
        servicePhotos.push(photo);
      }
      if (photo.isSamplePresentPhoto) {
        presentPhotos.push(photo);
      }
      if (photo.optionPhotoTypes.length > 0) {
        optionPhotos.push(photo);
      }
      if (photo.copyTypes.length > 0) {
        copyPhotos.push(photo);
      }
    });

    setSelectedCoverPhotos(coverPhotos);
    setSelectedBigPhotos(bigPhotos);
    setSelectedSmallPhotos(smallPhotos);
    setSelectedInsidePhotos(insidePhotos);
    setSelectedUniquePhotos(uniquePhotos);
    setSelectedServicePhotos(servicePhotos);
    setSelectedPresentPhotos(presentPhotos);
    setSelectedOptionPhotos(optionPhotos);
    setSelectedCopyPhotos(copyPhotos);    
  }

  const generateServiceImgLabel = (typeArray) => {
    if (photography == undefined) return '';
    let retString = '';
    let serviceNames = [];
    typeArray.map((type) => {
      let serviceIndex = photography.selectServicePhotoTypes.indexOf(type) + 1;
      let serviceName = SERVICE_INFO[type.split('&')[0]].name;
      serviceNames.push(serviceIndex + '.' + serviceName);
    });
    retString = serviceNames.join(' / ');

    return retString;
  }

  const generatePresentImgLabel = () => {
    if (photography == undefined) return '';
    return SAMPLE_PRESENT_INFO[photography.selectSamplePresent].name;
  }

  const generateOptionImgLabel = (typeArray) => {
    if (photography == undefined) return '';
    let retString = '';
    let optionNames = [];
    typeArray.map((type) => {
      let optionIndex = photography.selectOptionPhotoTypes.indexOf(type) + 1;
      let optionName = OPTION_INFO[type.split('&')[0]].name;
      optionNames.push(optionIndex + '.' + optionName);
    });
    retString = optionNames.join(' / ');
    return retString;
  }

  const generateCopyImgLabel = (typeArray) => {
    let retString = '';
    let copyNames = [];
    typeArray.map((type) => {
      console.log(type);
      copyNames.push(COPY_INFO[type.key].name + ' ' + type.count + '枚');
    });
    retString = copyNames.join(' / ');
    return retString;
  }

  const handleImageClick = async (photo) => {
    let resultUrl = '';
    await Storage.get(photo.s3path)
    .then(result => {
      if (result) {
        resultUrl = result;
      }
    })
    .catch(err => {
      console.log(err)
    });
    setImageViewerUrl(resultUrl);
    setIsPhotoZoomModalOpen(true);
  };

  const handleImageClose = (index) => {
    setIsPhotoZoomModalOpen(false);
  };

  return (
    <>
      <Helmet>
        <title> 日光写真館 プリント | 送信完了 </title>
      </Helmet>

      <PhotoImageZoomModal
        isOpen={isPhotoZoomModalOpen}
        imageUrl={imageViewerUrl}
        onClose={handleImageClose}
      />

      <Logo disabledLink />
      <Container>
        { sendCompleteStringDownload ?
          <Typography variant='body2' sx={{ fontWeight: 'bold' }} margin={2}>
          ありがとうございました。<br />
          お写真は１枚ずつダウンロード可能です。<br />
          ※ダウンロード可能期日：公開日より２週間ですのでご注意ください。<br />
          </Typography>
          :
          <Typography variant='body2' sx={{ fontWeight: 'bold' }} margin={2}>
          お写真選びありがとうございました。<br />
          お写真の仕上がりには1ヶ月〜1ヶ月半程かかります。<br />
          仕上がり次第ご連絡いたします。 <br />          
          </Typography>
        }
      </Container>
      <hr />
      <Container >
        { selectedCoverPhotos.length > 0 &&
        <Box>
          <Typography variant='h6' color='common.white' backgroundColor={'#4169e1'}>表紙</Typography>
          <Grid container spacing={3}>
            {selectedCoverPhotos.map((photo) => (
              <Grid item xs={12} sm={6} md={3} key={photo.id}>
                <PhotoCard
                  key={photo.id}
                  photo={photo}
                  photography={photography}
                  onClick={handleImageClick}
                  isSelectable={false}
                  isSimpleDisp={true} />
              </Grid>
            ))}
          </Grid>
        </Box>
        }
        { selectedBigPhotos.length > 0 &&
        <Box>
          <Typography variant='h6' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>大写真</Typography>
          <Grid container spacing={3}>
          {selectedBigPhotos.map((photo) => (
            <Grid item xs={12} sm={6} md={3} key={photo.id}>
              <PhotoCard
                key={photo.id}
                photo={photo}
                photography={photography}
                onClick={handleImageClick}
                isSelectable={false}
                isSimpleDisp={true} />
            </Grid>
          ))}
          </Grid>
        </Box>
        }
        { selectedSmallPhotos.length > 0 &&
        <Box>
          <Typography variant='h6' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>小写真</Typography>
          <Grid container spacing={3}>
          {selectedSmallPhotos.map((photo) => (
            <Grid item xs={12} sm={6} md={3} key={photo.id}>
              <PhotoCard
                key={photo.id}
                photo={photo}
                photography={photography}
                onClick={handleImageClick}
                isSelectable={false}
                isSimpleDisp={true} />
            </Grid>
          ))}
          </Grid>
        </Box>
        }
        { selectedInsidePhotos.length > 0 &&
        <Box>
          <Typography variant='h6' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>中表紙写真</Typography>
          <Grid container spacing={3}>
          {selectedInsidePhotos.map((photo) => (
            <Grid item xs={12} sm={6} md={3} key={photo.id}>
              <PhotoCard
                key={photo.id}
                photo={photo}
                photography={photography}
                onClick={handleImageClick}
                isSelectable={false}
                isSimpleDisp={true} />
            </Grid>
          ))}
          </Grid>
        </Box>
        }
        { selectedUniquePhotos.length > 0 &&
          <Box>
            <Typography variant='h6' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>{uniqueTitle}</Typography>
            <Grid container spacing={3}>
            {selectedUniquePhotos.map((photo) => (
              <Grid item xs={12} sm={6} md={3} key={photo.id}>
                <PhotoCard
                  key={photo.id}
                  photo={photo}
                  photography={photography}
                  onClick={handleImageClick}
                  isSelectable={false}
                  isSimpleDisp={true} />
              </Grid>
            ))}
            </Grid>
          </Box>
        }
        { selectedServicePhotos.length > 0 &&
        <Box>
          <Typography variant='h6' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>サービス</Typography>
          <Grid container spacing={3}>
          {selectedServicePhotos.map((photo) => (
            <Grid item xs={12} sm={6} md={3} key={photo.id}>
              <PhotoCard
                key={photo.id}
                photo={photo}
                photography={photography}
                onClick={handleImageClick}
                isSelectable={false}
                isSimpleDisp={true}
                imgLabel={generateServiceImgLabel(photo.servicePhotoTypes)} />
            </Grid>
          ))}
          </Grid>
        </Box>
        }
        { selectedPresentPhotos.length > 0 &&
        <Box>
          <Typography variant='h6' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>サンプル許可プレゼント</Typography>
          <Grid container spacing={3}>
          {selectedPresentPhotos.map((photo) => (
            <Grid item xs={12} sm={6} md={3} key={photo.id}>
              <PhotoCard
                key={photo.id}
                photo={photo}
                photography={photography}
                onClick={handleImageClick}
                isSelectable={false}
                isSimpleDisp={true}
                imgLabel={generatePresentImgLabel()} />
            </Grid>
          ))}
          </Grid>
        </Box>
        }
        { selectedOptionPhotos.length > 0 &&
        <Box>
          <Typography variant='h6' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>追加オプション</Typography>
          <Grid container spacing={3}>
          {selectedOptionPhotos.map((photo) => (
            <Grid item xs={12} sm={6} md={3} key={photo.id}>
              <PhotoCard
                key={photo.id}
                photo={photo}
                photography={photography}
                onClick={handleImageClick}
                isSelectable={false}
                isSimpleDisp={true}
                imgLabel={generateOptionImgLabel(photo.optionPhotoTypes)} />
            </Grid>
          ))}
          </Grid>
        </Box>
        }
        { selectedCopyPhotos.length > 0 &&
        <Box>
          <Typography variant='h6' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>焼き増し</Typography>
          <Grid container spacing={3}>
          {selectedCopyPhotos.map((photo) => (
            <Grid item xs={12} sm={6} md={3} key={photo.id}>
              <PhotoCard
                key={photo.id}
                photo={photo}
                photography={photography}
                onClick={handleImageClick}
                isSelectable={false}
                isSimpleDisp={true}
                imgLabel={generateCopyImgLabel(photo.copyTypes)} />
            </Grid>
          ))}
          </Grid>
        </Box>
        }
        <Box>
          <Typography variant='h6' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>撮影画像</Typography>
          <Grid container spacing={3}>
          {photos.map((photo) => (
            <Grid item xs={12} sm={6} md={3} key={photo.id}>
              <PhotoCard
                key={photo.id}
                photo={photo}
                photography={photography}
                onClick={handleImageClick}
                isSelectable={false}
                isSimpleDisp={true}
                imgLabel={generateCopyImgLabel(photo.copyTypes)} />
            </Grid>
          ))}
          </Grid>
        </Box>

      </Container>

    </>
  );
}
