import { TYPE_HYAKUNICHI } from '../constants';

/**
 * 撮影種別特有のタイトルを取得
 * @param {string} key キー
 * @param {object} object オブジェクト
 * @param {object} config 設定（オプション）
 * @returns 結果
 */
class NikkoUtil {
  static getUniqueTitle(photographyType) {
    if (photographyType == TYPE_HYAKUNICHI) {
      return '百日手形足形記念額';
    }
  }

  static downloadImage(url) {
    const a = document.createElement('a');
    a.href = url;
    a.download = 'image.jpg'; // ダウンロード時のファイル名を指定

    // リンクをクリックしてダウンロードを開始
    a.click();

    // リンクを削除
    a.remove();

    // var canvas_data = canvas.toDataURL();
    // var img_element = document.createElement("img")
    // img_element.src = canvas_data;
    // document.body.appendChild(img_element);

  }
}

export default NikkoUtil;