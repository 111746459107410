import * as React from 'react';
import PropTypes from 'prop-types';
// mui
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import { Stack } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
// amplify
import { API, graphqlOperation } from "aws-amplify";
import { createCustomer, createPhotography } from '../../graphql/mutations'
// components
import Iconify from '../../components/iconify';
import SpinButton from '../spinbutton/SpinButton';
// other
import { ZIP_API } from '../../constants';
import { PHOTOGRAPHY_INFO, SERVICE_INFO, SAMPLE_PRESENT_INFO } from '../../constants';
import { TYPE_HYAKUNICHI } from '../../constants';
import { STRINGS } from '../../strings';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import dayjs from 'dayjs';
import uuidjs from 'uuidjs';

CreatePhotograpyDialog.propTypes = {
  customers: PropTypes.array.isRequired,
  fetchPhotographies: PropTypes.func.isRequired,
  fetchCustomers: PropTypes.func.isRequired
};

const HYAKUNICHI_PLATE_SELECT = 'hyakunichi_plate_yes';
const HYAKUNICHI_PLATE_UNSELECT = 'hyakunichi_plate_no';

export default function CreatePhotograpyDialog({ customers, fetchPhotographies, fetchCustomers }) {
  const today = dayjs().format('YYYY-MM-DD');

  const prefectureRef = React.useRef(null);
  const municipalityRef = React.useRef(null);
  const addressRef = React.useRef(null);
  
  const [open, setOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);

  // const [customers, setCustomers] = React.useState([]);
  const [shootingDate, setShootingDate] = React.useState(today); 
  const [title, setTitle] = React.useState(''); 
  const [photographyCourses, setPhotographyCourses] = React.useState([]);
  const [samplePresent, setSamplePresent] = React.useState(''); 
  const [needHyakunichiPlate, setNeedHyakunichiPlate] = React.useState(HYAKUNICHI_PLATE_UNSELECT); 
  const [isUniqueItem, setIsUniqueItem] = React.useState(false); 
  const [course, setCourse] = React.useState('');
  const [customerFamilyName, setCustomerFamilyName] = React.useState('');
  const [customerFirstName, setCustomerFirstName] = React.useState('');
  const [tel, setTel] = React.useState(''); 
  const [zipcode, setZipcode] = React.useState(''); 
  const [prefecture, setPrefecture] = React.useState(''); 
  const [municipality, setMunicipality] = React.useState(''); 
  const [address, setAddress] = React.useState(''); 
  const [building, setBuilding] = React.useState(''); 
  const [description, setDescription] = React.useState(''); 

  const [titleError, setTitleError] = React.useState(false); 
  const [courseError, setCourseError] = React.useState(false); 
  const [customerFirstNameError, setCustomerFirstNameError] = React.useState(false);
  const [customerFamilyNameError, setCustomerFamilyNameError] = React.useState(false);
  const [telError, setTelError] = React.useState(false); 
  const [zipcodeError, setZipcodeError] = React.useState(false);
  const [addressError, setAddressError] = React.useState(false);

  const [serviceCountList, setServiceCountList] =
    React.useState(Array.from({ length: Object.keys(SERVICE_INFO).length }, () => 0));

  const initalizeInputState = () => {
    setTitle('');
    setCourse('');
    setCustomerFamilyName('');
    setCustomerFirstName('');
    setTel('');
    setZipcode('');
    setPrefecture('');
    setMunicipality('');
    setAddress('');
    setDescription('');

    setTitleError(false);
    setCourseError(false);
    setCustomerFamilyNameError(false);
    setCustomerFirstNameError(false);
    setTelError(false);
    setZipcodeError(false);
    setAddressError(false);
  }

  const handleClickOpen = () => {
    setOpen(true);
    initalizeInputState();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleChangeTel = (value) => {
    let select_customer = customers.find(function(customer) {
      return customer.id == value.id;
    })
    setCustomerFamilyName(select_customer.family_name);
    setCustomerFirstName(select_customer.first_name);
    setTel(select_customer.tel);
    setZipcode(select_customer.zipcode);
    setPrefecture(select_customer.prefecture);
    setMunicipality(select_customer.municipality);
    setAddress(select_customer.address);
    setDescription(select_customer.description);
  }

  // 郵便番号入力時のコールバック
  const handleChangeZipcode = (event) => {
    let zipcloudAPI = ZIP_API.SEARCH;
    let zipcode = event.target.value.replace('-', '');
    setZipcode(zipcode);
  
    // 7文字の場合は郵便番号から住所を入力
    if (zipcode.length === 7) {
      const res = axios.get(
        zipcloudAPI,
        {
          params: {
            zipcode: zipcode
          }
        }
      ).then(
        response => {
          if (response.data.results) {
            // 取得できた場合は都道府県と市区町村を設定
            setPrefecture(response.data.results[0]["address1"]);
            setMunicipality(response.data.results[0]["address2"]+response.data.results[0]["address3"]);
          }
        }
      ).catch(
        err => {
          alert(STRINGS.TOAST_FETCH_ADDRESS_ERR);
          setPrefecture('');
          setMunicipality('');
          console.log('err:', err);
        }
      );
    } else {
      setPrefecture('');
      setMunicipality('');
    }
  }

  // 入力ダイアログ「確認」押下
  const handleCreateValidation = () => {
    // Validation 実行し確認ダイアログを開く
    if (formValidation()) {
      setOpen(false);
      setConfirmOpen(true);
    }
  }

  // 確認ダイアログ「登録」ボタン押下
  const handleCreatePhotograpy = async () => {
    // 顧客情報が追加された場合は住所確認
    // 顧客情報が変更された場合は変更確認＆住所確認
    var saveCustomer = null;    

    // 顧客情報を登録
    const inputCustomer = {
      family_name: customerFamilyName,
      first_name: customerFirstName,
      zipcode: zipcode,
      prefecture: prefecture,
      municipality: municipality,
      building: building,
      address: address,
      tel: tel
    };

    // すでに登録済み顧客であれば顧客情報は登録しない
    const result = customers.find((c) => c.tel === inputCustomer.tel);
    if (!result) {
      console.log('a')
      // データが存在しなかった時の処理
      const customerItem = await API.graphql(
        graphqlOperation(createCustomer, {
          input: inputCustomer
        }),
        ).catch(err => {
          console.error('createCustomer', err);
          toast.error(STRINGS.TOAST_REGIST_PHOTOGRAPY_ERR, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
          return;
        });
      saveCustomer = customerItem.data.createCustomer;
      fetchCustomers();
    } else {
      console.log(result);
      saveCustomer = result; 
    }

    // 撮影情報の登録
    console.log(shootingDate);
    let servicePhotoTypes = [];
    serviceCountList.forEach( (count, index)  => {
      Object.keys(SERVICE_INFO).map((key) => {
        if (SERVICE_INFO[key].index == index) {
          for (let i = 0; count > i; i++) {
            servicePhotoTypes.push(key + '&' + uuidjs.generate());
          }
        }
      })
    });

    // // S3 フォルダ名の作成 (ここでは S3 に put しない)
    const s3path = uuidjs.generate();
    const photography = {
      title: title,
      course: course,
      isSelected: false,
      s3path: s3path,
      customerID: saveCustomer.id,
      shootingDate: shootingDate,
      isUniqueItem: isUniqueItem,
      selectServicePhotoTypes: servicePhotoTypes,
      selectOptionPhotoTypes: [],
      selectSamplePresent: samplePresent,
      description: description,
      comment: ''
    };
    const photographyItem = await API.graphql(
      graphqlOperation(createPhotography, {
        input: photography
      }),
    ).catch(err => {
      // rollback
      console.error("createPhotography", err)
      toast.error(STRINGS.TOAST_REGIST_PHOTOGRAPY_ERR, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      return
    });
    console.log(photographyItem);
    const savedPhotography = photographyItem.data.createPhotography;

    // 成功を通知
    toast.success(STRINGS.TOAST_REGIST_PHOTOGRAPY, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
      fetchPhotographies();

      setConfirmOpen(false);
  }

  const formValidation = () => {
    let valid = true;

    // タイトルのチェック
    setTitleError(!title);
    valid &&= Boolean(title);

    // お客様名の入力チェック
    setCustomerFamilyNameError(!customerFamilyName);
    valid &&= Boolean(customerFamilyName);
    setCustomerFirstNameError(!customerFirstName);
    valid &&= Boolean(customerFirstName);

    // 電話番号の入力チェック
    setTelError(!tel);
    valid &&= Boolean(tel);
    
    // 郵便番号チェック(ひとまずは都道府県と市区町村が取れているかで判定)
    // let isValidZipcode = prefectureRef.current.value && municipalityRef.current.value;
    // setZipcodeError(!isValidZipcode);
    // valid &&= isValidZipcode;
    // console.log(zipcode)

    // 番地の入力チェック
    // setAddressError(!addressRef.current.value);
    // valid &&= Boolean(addressRef.current.value);

    // console.log(valid)

    return valid;
  };

  return (
    <div>
      <ToastContainer />
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
        </Typography>
        <Button onClick={handleClickOpen} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            撮影を追加する
        </Button>
      </Stack>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>撮影を追加</DialogTitle>
        <DialogContent>
          <DialogContentText>
            登録する撮影情報の入力
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="create-dialog-date-field"
                value={shootingDate}
                label="撮影日時"
                type="date"
                inputProps={ {required: true} }
                margin="normal"
                fullWidth={true}
                onChange={
                  (event) => {
                    setShootingDate(event.target.value)
                  }
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="create-dialog-title-label">撮影タイトル</InputLabel>
                <Select
                  labelId="create-dialog-title-label"
                  id="create-dialog-title-field"
                  value={title}
                  defaultValue=''
                  label="撮影タイトル"
                  displayEmpty
                  error={titleError}
                  fullWidth={true}
                  onChange={
                    (event) => {
                      setTitle(event.target.value);
                      if (event.target.value != TYPE_HYAKUNICHI) {
                        // 百日でなければ記念額は不要とする
                        setIsUniqueItem(false);
                      }
                      console.log(event.target.value);
                      let photograpy_info = PHOTOGRAPHY_INFO[event.target.value];

                      // コース名の取り出し
                      var course_names = photograpy_info.courses.map((item) => {
                        return item.name;
                      });
                      // コースのセレクトボックスへ設定
                      setPhotographyCourses(course_names);
                      if (titleError) {
                        setTitleError(false);
                      }
                    }
                  }>
                  { 
                    // 撮影タイトルリストの取り出し
                    Object.keys(PHOTOGRAPHY_INFO).map(( phototitle ) => {
                      let photograpy_info = PHOTOGRAPHY_INFO[phototitle];
                      return <MenuItem key={phototitle} value={phototitle}>{photograpy_info.name}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="create-dialog-course-label">コース</InputLabel>
                <Select
                  labelId="create-dialog-course-label"
                  id="create-dialog-course-field"
                  value={course}
                  defaultValue=''
                  label="コース"
                  displayEmpty
                  error={courseError}
                  fullWidth={true}
                  onChange={
                    (event) => {
                      setCourse(event.target.value);
                      if (courseError) {
                        setCourseError(false);
                      }
                    }
                  }>
                  {
                    photographyCourses.map(( course_value ) => {
                      return <MenuItem value={course_value} key={course_value}>{course_value}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <DialogContentText
            marginTop={2} marginBottom={2}>
            サービス情報の入力
          </DialogContentText>
            <Grid item xs={12} marginTop={-3}>
            {Object.keys(SERVICE_INFO).map((key) => (
              <Grid container spacing={1} key={key}>
                <Grid item xs={6} textAlign='center' marginTop={1.5}>
                  <Typography alignSelf='center' lineHeight="1">{SERVICE_INFO[key].name}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <SpinButton value={serviceCountList[SERVICE_INFO[key].index]} unit="個" w
                    onChange= { (value) => {
                      // 数量変更
                      console.log('SpinButton onChange' + value)
                      if (value < 0 ) {
                        value = 0;
                      }
                      const newServiceCountList = [...serviceCountList];
                      newServiceCountList[SERVICE_INFO[key].index] = value;
                      setServiceCountList(newServiceCountList); 
                      console.log(newServiceCountList);
                    } 
                  } />
                </Grid>
              </Grid>
            ))}
            <Grid container spacing={2}>
            <Grid item xs={12} textAlign='center' marginTop={1.5}>
            <FormControl fullWidth>
              <InputLabel id="create-dialog-sample">サンプル利用許可</InputLabel>
              <Select
                labelId="create-dialog-sample-label"
                id="create-dialog-sample-field"
                value={samplePresent}
                defaultValue=''
                label="サンプル利用許可プレゼント"
                displayEmpty
                fullWidth={true}
                onChange={
                  (event) => {
                    console.log(event.target.value)
                    console.log(SAMPLE_PRESENT_INFO[event.target.value])
                    setSamplePresent(event.target.value);
                  }
                }>
                { 
                  // プレゼントリストの取り出し
                  Object.keys(SAMPLE_PRESENT_INFO).map(( presentTitle ) => {
                    let present_info = SAMPLE_PRESENT_INFO[presentTitle];
                    return <MenuItem key={presentTitle} value={presentTitle}>{present_info.name}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
            </Grid>
            { title == TYPE_HYAKUNICHI &&
            <Grid item xs={12} >
            <FormControl fullWidth>
              <InputLabel id="create-dialog-hakunichi">百日手形足形記念額</InputLabel>
              <Select
                labelId="create-dialog-hakunichi-label"
                value={needHyakunichiPlate}
                label="百日手形足形記念額"
                fullWidth={true}
                onChange={
                  (event) => {
                    console.log(event.target.value);
                    setNeedHyakunichiPlate(event.target.value);

                    setIsUniqueItem(HYAKUNICHI_PLATE_SELECT == event.target.value);
                  }
                }>
                <MenuItem value={HYAKUNICHI_PLATE_SELECT}>要</MenuItem>
                <MenuItem value={HYAKUNICHI_PLATE_UNSELECT}>不要</MenuItem>
              </Select>
            </FormControl>
            </Grid>
            }
            </Grid>
          </Grid>
          <DialogContentText
            marginTop={2} marginBottom={2}>
            お客様の情報の入力
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                id="create-dialog-tel-ac"
                value={tel}
                freeSolo
                fullWidth
                options={
                  customers.map(( customer ) => {
                    return { label: customer.tel + " (" + customer.family_name + customer.first_name + (")"), id: customer.id };
                  })
                }
                onChange={(event, value) => {
                  console.log(event)
                  console.log(value)
                  handleChangeTel(value)
                }}
                renderInput={(params) =>
                  <TextField {...params}
                    id="create-dialog-tel-field"
                    label="電話番号"
                    value={tel}
                    error={telError}
                    helperText={telError && "電話番号を入力して下さい"}
                    onChange={
                      (event) => {
                        setTel(event.target.value.trim());
                        if (telError) {
                          setTelError(false);
                        }
                      }
                    }
                  />}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="create-dialog-family-name-field"
                label="姓"
                value={customerFamilyName}
                error={customerFamilyNameError}
                helperText={customerFamilyNameError && "姓を入力して下さい"}
                fullWidth={true}
                onChange={
                  (event) => {
                    setCustomerFamilyName(event.target.value.trim());
                    if (customerFamilyNameError) {
                      setCustomerFamilyNameError(false);
                    }
                  }
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="create-dialog-first-name-field"
                label="名"
                value={customerFirstName}
                error={customerFirstNameError}
                helperText={customerFirstNameError && "名を入力して下さい"}
                fullWidth={true}
                onChange={
                  (event) => {
                    setCustomerFirstName(event.target.value.trim());
                    if (customerFirstNameError) {
                      setCustomerFirstNameError(false);
                    }
                  }
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="create-dialog-zipcode-field"
                InputProps={{
                  startAdornment: <InputAdornment position="start">〒</InputAdornment>
                }}
                label="郵便番号"
                value={zipcode}
                inputProps={ {required: true} }
                error={zipcodeError}
                helperText={zipcodeError && "郵便番号が正しくありません"}
                margin="dense"
                fullWidth={true}
                onChange={
                  (event) => {
                    handleChangeZipcode(event);
                    if (zipcodeError) {
                      setZipcodeError(false);
                    }
                  }
                }
              />
              <TextField
                id="create-dialog-prefecture-field"
                inputRef={prefectureRef}
                label="都道府県"
                value={prefecture}
                inputProps={ {required: true} }
                margin="dense"
                fullWidth={true}
                onChange={event => setPrefecture(event.target.value)}
              />
              <TextField
                id="create-dialog-municipalities-field"
                inputRef={municipalityRef}
                label="市区町村"
                value={municipality}
                margin="dense"
                fullWidth={true}
                onChange={event => setMunicipality(event.target.value)}
              />
              <TextField
                id="create-dialog-address-field"
                inputRef={addressRef}
                label="番地"
                value={address}
                error={addressError}
                helperText={addressError && "番地を入力して下さい"}
                margin="dense"
                fullWidth={true}
                onChange={
                  (event) => {
                    setAddress(event.target.value.trim());
                    if (addressError) {
                      setAddressError(false);
                    }
                  }
                }
              />
              <TextField
                id="create-dialog-address-field"
                label="アパート・マンション名"
                value={building}
                margin="dense"
                fullWidth={true}
                onChange={
                  (event) => {
                    setBuilding(event.target.value.trim());
                  }
                }
              />
            </Grid>
          </Grid>

          <DialogContentText
            marginTop={2}>
            補足情報の入力
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="create-dialog-description-field"
                label="メモ"
                value={description}
                fullWidth={true}
                minRows={10}
                onChange={
                  (event) => {
                    setDescription(event.target.value.trim());
                  }
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined"
            onClick={
              (event) => {
                initalizeInputState();
                handleClose();
              }
            }>キャンセル
          </Button>
          <Button variant="contained"
            onClick={handleCreateValidation}>確認
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmOpen} onClose={handleConfirmClose}>
        <DialogTitle>お客様の情報の確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            お客様の情報が間違っていないか確認して下さい
          </DialogContentText>
          <br />
          <DialogContentText>
            <b>お名前：</b>{customerFamilyName + " " + customerFirstName + " 様"}
          </DialogContentText>
          <DialogContentText>
            <b>電話番号：</b>{tel}
          </DialogContentText>
          <DialogContentText>
            <b>住所：</b>{prefecture}{municipality}{address}{building}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined"
            onClick={
              (event) => {
                initalizeInputState()
                handleConfirmClose()
              }
            }>キャンセル</Button>
          <Button variant="contained" onClick={handleCreatePhotograpy}>登録</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}