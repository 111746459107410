import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { IconButton, Grid, Typography, Box } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
//
import Label from '../label';

// ----------------------------------------------------------------------

const SpinButton = forwardRef(({ children, sx, value = 0, unit, onChange, ...other }, ref) => {
  const theme = useTheme();

  return (
    <Grid container>
      <Grid item xs={4} marginTop={-1}>
        <IconButton color="primary" size="medium" onClick={() => {
          console.log('SpinBtton plus');
          if (value <= 0) return;
          value--;
          if (onChange) onChange(value);
        }} >
          <RemoveCircleIcon fontSize="inherit" />
        </IconButton>
      </Grid>
      <Grid item xs={4} textAlign={'center'}>
      <Typography variant='body' paddingLeft={0.5} textAlign="center">{value}{unit}</Typography>
      </Grid>
      <Grid item xs={4} marginTop={-1}>
      <IconButton color="primary" size="medium" onClick={() => {
          console.log('SpinBtton plus');
          value++;
          if (onChange) onChange(value);
        }}>
        <AddCircleIcon fontSize="inherit" />
      </IconButton>
      </Grid>
    </Grid>
  );
});

SpinButton.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default SpinButton;
