import * as React from 'react';
import PropTypes from 'prop-types';
// mui
import { Grid } from '@mui/material';
// components
import PhotographyCard from './PhotographyCard';
// ----------------------------------------------------------------------

PhotographyList.propTypes = {
  photographies: PropTypes.array.isRequired,
  customers: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  onPhotographyDelete: PropTypes.func
};

export default function PhotographyList({ photographies, customers, onClick, onPhotographyDelete, ...other }) {
  const [dispPhotographs, setDispPhotographies] = React.useState([]);
  const [dispCustomers, setDispCustomers] = React.useState([]);

  React.useEffect(() => {
    if (photographies.length > 0 && customers.length > 0) {
      setDispPhotographies(photographies);
      setDispCustomers(customers);
    }
  });

  const handlePhotographyClick = (photography) => {
    onClick(photography);
  };

  const handlePhotographyDelete = (photography) => {
    onPhotographyDelete(photography);
  };
    
  return (
    <Grid container spacing={3} {...other}>
      {dispPhotographs.map((photography) => (
        <Grid key={photography.id} item xs={12} sm={6} md={3}>
          <PhotographyCard photography={photography} customer={
            dispCustomers.find(function(customer) {
              return customer.id == photography.customerID
            })
          }
          onClick={handlePhotographyClick}
          onDelete={handlePhotographyDelete} />
        </Grid>
      ))}
    </Grid>
  );
}
