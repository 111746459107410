import * as React from 'react';
import PropTypes from 'prop-types';
// mui
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, Button } from '@mui/material';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
// other
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageGallery from 'react-image-gallery';

import "../../utils/image-gallery.css";
import { COPY_INFO } from '../../constants';

PhotoSelectCopyInfoDialog.propTypes = {
  showDialog: PropTypes.bool,
  onClose: PropTypes.func,
};

const copyInfomation = () => {
  let retStrings = [];
  Object.keys(COPY_INFO).map((key) => {
    let info = COPY_INFO[key];
    retStrings.push(info.name);
  });
  return retStrings.map((str)=> {
      <div>{str}<br /></div>
    })
}

export default function PhotoSelectCopyInfoDialog({ showDialog, onClose }) {
  // const [open, setOpen] = React.useState(false);

  return (
    <div>
      <ToastContainer />
      <Dialog open={showDialog}>
        <DialogTitle>焼き増しのご案内</DialogTitle>
        <DialogContent>
          お写真の焼き増しができます<br />
          ※台紙付きの焼き増しのご注文は追加オプションをご利用ください<br />
          焼き増しのご注文をされない場合はそのまま「決定」を押してください<br /><br />
          {
            // 焼き増し情報取得
            Object.keys(COPY_INFO).map((key) => (
              <Box key={key}>
              ・{COPY_INFO[key].name + ' (' + COPY_INFO[key].size + ') ¥' + COPY_INFO[key].price.toLocaleString()}
              </Box>
            ))
          }
        </DialogContent>
        <DialogActions>
          <Button variant='contained'
            onClick={onClose}
            >OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}