
// 表示文言
export const STRINGS = {
    STATUS_NO_UPLOAD: '写真アップロード待ち',
    STATUS_SELECTING_PHOTO: '写真選択中',
    STATUS_SELECTED_PHOTO: '写真選択済み',
    STATUS_CONFIRMED_PHOTO: '確認済み',

    TOAST_REGIST_PHOTOGRAPY: '撮影情報を登録しました',
    TOAST_FETCH_ADDRESS_ERR: '住所の取得に失敗しました',
    TOAST_REGIST_PHOTOGRAPY_ERR: '撮影情報の登録に失敗しました',
    TOAST_UPDATE_PHOTOGRAPY: '選択された写真を送信しました',
    TOAST_UPDATE_PHOTOGRAPY_ERR: '撮影情報の更新に失敗しました',
    TOAST_DELETE_PHOTOGRAPY: '撮影情報を削除しました',

    TOAST_UPLOAD_PHOTO_ERR: '画像のアップロードに失敗しました',

    TOAST_SELECT_PHOTO_URL_COPY: 'お客様用のURLをクリップボードにコピーしました',

    SELECT_PHOTO_TYPE_NAME_COVER: '表紙',
    SELECT_PHOTO_TYPE_NAME_BIG: '大',
    SELECT_PHOTO_TYPE_NAME_SMALL: '小',
    SELECT_PHOTO_TYPE_NAME_INSIDE: '中表紙',
    SELECT_PHOTO_TYPE_NAME_UNIQUE: '撮影種別固有',
    SELECT_PHOTO_TYPE_NAME_SERVICE: 'サービス',
    SELECT_PHOTO_TYPE_NAME_PRESENT: 'サンプル利用プレゼント',
    SELECT_PHOTO_TYPE_NAME_OPTION: '追加オプション',
    SELECT_PHOTO_TYPE_NAME_COPY: '焼き増し',

    SELECT_PHOTO_DESCRIPTION_COVER: '「表紙」としてプリントする写真を選んでください',
    SELECT_PHOTO_DESCRIPTION_BIG: '「大写真」としてプリントする写真を選んでください',
    SELECT_PHOTO_DESCRIPTION_SMALL: '「小写真」としてプリントする写真を選んでください',
    SELECT_PHOTO_DESCRIPTION_INSIDE: '「中表紙」としてプリントする写真を選んでください',
    SELECT_PHOTO_DESCRIPTION_UNIQUE_HYAKUNICHI: '「百日手形足形記念額」としてプリントする写真を選んでください',
    SELECT_PHOTO_DESCRIPTION_SERVICE: '「サービス(service)」用の写真を選んでください',
    SELECT_PHOTO_DESCRIPTION_PRESENT: '「サンプル許可プレゼント(present)」用の写真を選んでください',
    SELECT_PHOTO_DESCRIPTION_OPTION: '「追加オプション(option)」用の写真を選んでください',
    SELECT_PHOTO_DESCRIPTION_COPY: '焼き増しする写真を選んでください',
}