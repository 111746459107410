import * as React from 'react';
import PropTypes from 'prop-types';
// mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Box, Typography, IconButton} from '@mui/material';
// other

PhotographyDeleteConfirmDialog.propTypes = {
  open: PropTypes.bool,
  targetPhotography: PropTypes.object,
  onOK: PropTypes.func,
  onCancel: PropTypes.func
};

export default function PhotographyDeleteConfirmDialog({ open, targetPhotography, title='', onOK, onCancel }) {

  const handleSend = () => {
    onOK(targetPhotography);
  };

  const handleClose = () => {
    onCancel();
  };

  return (
    <div>
      <Dialog open={open}>
        <DialogTitle>削除確認</DialogTitle>
        <Box position="absolute" top={0} right={0}>
          <IconButton>
          </IconButton>
        </Box>
        <DialogContent>
          <Typography>撮影情報を削除してもよいですか？<br />{title}</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={handleClose}>
            キャンセル
          </Button>
          <Button color="secondary" variant="contained" onClick={handleSend} >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}