/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPhotography = /* GraphQL */ `
  mutation CreatePhotography(
    $input: CreatePhotographyInput!
    $condition: ModelPhotographyConditionInput
  ) {
    createPhotography(input: $input, condition: $condition) {
      id
      title
      course
      isSelected
      isPrintConfirmed
      shootingDate
      photos {
        items {
          id
          name
          photographyID
          isCoverPhoto
          isBigPhoto
          isSmallPhoto
          isInsideCoverPhoto
          isAlbumPhoto
          isUniqueItemPhoto
          isServicePhoto
          servicePhotoTypes
          isOptionPhoto
          optionPhotoTypes
          isCopyPhoto
          copyTypes
          isSamplePresentPhoto
          s3path
          thumbnailS3Path
          createdAt
          updatedAt
        }
        nextToken
      }
      selectServicePhotoTypes
      customerID
      selectOptionPhotoTypes
      customer {
        id
        family_name
        first_name
        prefecture
        municipality
        address
        building
        zipcode
        tel
        createdAt
        updatedAt
      }
      selectCopyPhoto
      selectSamplePresent
      isUniqueItem
      description
      comment
      s3path
      createdAt
      updatedAt
      photographyCustomerId
    }
  }
`;
export const updatePhotography = /* GraphQL */ `
  mutation UpdatePhotography(
    $input: UpdatePhotographyInput!
    $condition: ModelPhotographyConditionInput
  ) {
    updatePhotography(input: $input, condition: $condition) {
      id
      title
      course
      isSelected
      isPrintConfirmed
      shootingDate
      photos {
        items {
          id
          name
          photographyID
          isCoverPhoto
          isBigPhoto
          isSmallPhoto
          isInsideCoverPhoto
          isAlbumPhoto
          isUniqueItemPhoto
          isServicePhoto
          servicePhotoTypes
          isOptionPhoto
          optionPhotoTypes
          isCopyPhoto
          copyTypes
          isSamplePresentPhoto
          s3path
          thumbnailS3Path
          createdAt
          updatedAt
        }
        nextToken
      }
      selectServicePhotoTypes
      customerID
      selectOptionPhotoTypes
      customer {
        id
        family_name
        first_name
        prefecture
        municipality
        address
        building
        zipcode
        tel
        createdAt
        updatedAt
      }
      selectCopyPhoto
      selectSamplePresent
      isUniqueItem
      description
      comment
      s3path
      createdAt
      updatedAt
      photographyCustomerId
    }
  }
`;
export const deletePhotography = /* GraphQL */ `
  mutation DeletePhotography(
    $input: DeletePhotographyInput!
    $condition: ModelPhotographyConditionInput
  ) {
    deletePhotography(input: $input, condition: $condition) {
      id
      title
      course
      isSelected
      isPrintConfirmed
      shootingDate
      photos {
        items {
          id
          name
          photographyID
          isCoverPhoto
          isBigPhoto
          isSmallPhoto
          isInsideCoverPhoto
          isAlbumPhoto
          isUniqueItemPhoto
          isServicePhoto
          servicePhotoTypes
          isOptionPhoto
          optionPhotoTypes
          isCopyPhoto
          copyTypes
          isSamplePresentPhoto
          s3path
          thumbnailS3Path
          createdAt
          updatedAt
        }
        nextToken
      }
      selectServicePhotoTypes
      customerID
      selectOptionPhotoTypes
      customer {
        id
        family_name
        first_name
        prefecture
        municipality
        address
        building
        zipcode
        tel
        createdAt
        updatedAt
      }
      selectCopyPhoto
      selectSamplePresent
      isUniqueItem
      description
      comment
      s3path
      createdAt
      updatedAt
      photographyCustomerId
    }
  }
`;
export const createPhoto = /* GraphQL */ `
  mutation CreatePhoto(
    $input: CreatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    createPhoto(input: $input, condition: $condition) {
      id
      name
      photographyID
      isCoverPhoto
      isBigPhoto
      isSmallPhoto
      isInsideCoverPhoto
      isAlbumPhoto
      isUniqueItemPhoto
      isServicePhoto
      servicePhotoTypes
      isOptionPhoto
      optionPhotoTypes
      isCopyPhoto
      copyTypes
      isSamplePresentPhoto
      s3path
      thumbnailS3Path
      createdAt
      updatedAt
    }
  }
`;
export const updatePhoto = /* GraphQL */ `
  mutation UpdatePhoto(
    $input: UpdatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    updatePhoto(input: $input, condition: $condition) {
      id
      name
      photographyID
      isCoverPhoto
      isBigPhoto
      isSmallPhoto
      isInsideCoverPhoto
      isAlbumPhoto
      isUniqueItemPhoto
      isServicePhoto
      servicePhotoTypes
      isOptionPhoto
      optionPhotoTypes
      isCopyPhoto
      copyTypes
      isSamplePresentPhoto
      s3path
      thumbnailS3Path
      createdAt
      updatedAt
    }
  }
`;
export const deletePhoto = /* GraphQL */ `
  mutation DeletePhoto(
    $input: DeletePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    deletePhoto(input: $input, condition: $condition) {
      id
      name
      photographyID
      isCoverPhoto
      isBigPhoto
      isSmallPhoto
      isInsideCoverPhoto
      isAlbumPhoto
      isUniqueItemPhoto
      isServicePhoto
      servicePhotoTypes
      isOptionPhoto
      optionPhotoTypes
      isCopyPhoto
      copyTypes
      isSamplePresentPhoto
      s3path
      thumbnailS3Path
      createdAt
      updatedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      family_name
      first_name
      prefecture
      municipality
      address
      building
      zipcode
      tel
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      family_name
      first_name
      prefecture
      municipality
      address
      building
      zipcode
      tel
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      family_name
      first_name
      prefecture
      municipality
      address
      building
      zipcode
      tel
      createdAt
      updatedAt
    }
  }
`;
