import * as React from "react";
import PropTypes from "prop-types";
// mui
import { Card, CardActionArea, Stack, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
// components
import SpinButton from '../spinbutton';
// other
import { COPY_INFO } from '../../constants';
import Label from '../label';

export default function PhotographyCopySelectDialog({showDialog, photo, index, copyCountList, onCopyCountChanged, onOK, onCancel, isDoneBtnEnable}) {

  const StyledProductImg = styled("img")({
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "contain",
    position: "absolute"
  });

  return (
    <div>
      <Dialog open={showDialog}>
        <DialogTitle>焼き増し</DialogTitle>
        <DialogContent>
          <DialogContentText marginBottom={1}>選択した写真の焼き増し枚数を入力してください</DialogContentText>
            {Object.keys(COPY_INFO).map((key) => (
              <Grid container spacing={1} key={key}>
                <Grid item xs={6}>
                  <Typography variant='body2' display="flex" lineHeight="1">
                    <b>{COPY_INFO[key].name + ' ¥' + COPY_INFO[key].price.toLocaleString()}<br />({COPY_INFO[key].size})</b>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                  <SpinButton value={copyCountList[COPY_INFO[key].index]} unit="枚"
                    onChange= { (value) => {
                      onCopyCountChanged(index, photo, key, value);
                    } 
                  } />
                </Grid>
              </Grid>
            ))}
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={
              (event) => {
                onCancel(index);
              }
            }>
            キャンセル
          </Button>
          <Button color="secondary" variant="contained" disabled={!isDoneBtnEnable}
            onClick={
              (event) => {
                onOK(index);
              }
            }>
            決定　
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
