import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// amplify
import { API, Storage, graphqlOperation } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { listPhotographies } from "../graphql/queries";
import { listCustomers } from '../graphql/queries';
import { deletePhotography, deletePhoto } from '../graphql/mutations';
// @mui
import { Container, Stack, Typography } from '@mui/material';
// components
import { PhotographySort, PhotographyList } from '../sections/@dashboard/photography';
import { CreatePhotograpyDialog, PhotographyDeleteConfirmDialog } from '../components/dialog'

// other
import { Helmet } from 'react-helmet-async';
import { ToastContainer, toast } from 'react-toastify';
import { STRINGS } from '../strings';
import { PATH } from '../constants';

// ----------------------------------------------------------------------

export default function PhotographyPage() {
  const { route } = useAuthenticator((context) => [context.route]);

  const [openFilter, setOpenFilter] = React.useState(false);
  const [customers, setCustomers] = React.useState([]);
  const [photographies, setPhotographies] = React.useState([]);
  const [isFetch, setIsFetch] = React.useState(true);
  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] = React.useState(false);
  const [deleteTargetPhotography, setDeleteTargetPhotography] = React.useState(null);
  const [isSortOldest, setIsSortOldest] = React.useState(true);

  const navigate = useNavigate();

  console.log('PhotographyPage')

  function initalizeData() {
    if (!isFetch) {
      return
    }
    fetchCustomers()
    fetchPhotographies()
    setIsFetch(false)
  }
  const fetchPhotographies = async () => {
    console.log("fetchPhotographies")
    // 撮影情報を取得
    const fetchPhotographies = await API.graphql(
      graphqlOperation(listPhotographies, { limit: 10000})
    );
    // 初回データを保持
    var allPhotographies = fetchPhotographies.data.listPhotographies.items;
    // 続きを取得
    var nextToken = fetchPhotographies.data.listPhotographies.nextToken;
    while (nextToken != null) {
      const nextPageQuery = await API.graphql(
        graphqlOperation(listPhotographies, { limit: 10000, nextToken })
      );
      const nextPageData = nextPageQuery.data;
      allPhotographies = allPhotographies.concat(nextPageData.listPhotographies.items);
      nextToken = nextPageData.listPhotographies.nextToken;
    }

    var array = allPhotographies.sort(sortCompareFn);
    console.log(array);

    setPhotographies(array);
  }

  const fetchCustomers = async () => {
    // お客様情報を取得
    const fetchCustomers = await API.graphql(
      graphqlOperation(listCustomers, { limit: 10000})
    );
    // 初回データを保持
    var allCustomers = fetchCustomers.data.listCustomers.items;
    // 続きを取得
    var nextToken = fetchCustomers.data.listCustomers.nextToken;
    while (nextToken != null) {
      const nextPageQuery = await API.graphql(
        graphqlOperation(listCustomers, { limit: 10000, nextToken })
      );
      const nextPageData = nextPageQuery.data;
      allCustomers = allCustomers.concat(nextPageData.listCustomers.items);
      nextToken = nextPageData.listCustomers.nextToken;
    }

    setCustomers(allCustomers)  
    console.log(allCustomers)
  }
  initalizeData()

  const deletePhotographyData = async (photography) => {
    // 撮影情報の削除
    const deleteResult = await API.graphql(
      graphqlOperation(deletePhotography, {
        input: {
          id: photography.id
        }
      })
    );
    const deletedPhotography = deleteResult.data.deletePhotography;
    if (deletedPhotography) {
      toast.success(STRINGS.TOAST_DELETE_PHOTOGRAPY, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      setDeleteTargetPhotography(null);
      fetchPhotographies();
    } else {
      return;
    }

    // 写真情報の削除
    if (deletedPhotography.photos.items.length > 0) {
      deletedPhotography.photos.items.map( async (photo) => {
        const deletePhotoResult = await API.graphql({
          query: deletePhoto,
          variables: {
            input: {
                id: photo.id
            }
          }
        });
        const deletedPhoto = deletePhotoResult.data.deletePhoto;
        // S3 の画像ファイルを削除
        await Storage.remove(deletedPhoto.s3path);
        await Storage.remove(deletedPhoto.thumbnailS3Path);
      })
    }
  }

  const sortCompareFn = (a,b) => {
    if (isSortOldest) {
      if (a.shootingDate == b.shootingDate) {
        return a.createdAt < b.createdAt ? 1 : -1;
      }
      return a.shootingDate < b.shootingDate ? 1 : -1;
    } else {
      if (a.shootingDate == b.shootingDate) {
        return a.createdAt > b.createdAt ? 1 : -1;
      }
      return a.shootingDate > b.shootingDate ? 1 : -1;
    }
  }

  const handleSortChange = (sort) => {
    let array = null;
    console.log(sort);
    setIsSortOldest(sort == 'oldest');
    array = photographies.sort(sortCompareFn);
    setPhotographies(array);
  };

  const handleDeleteConfirmOK = (photography) => {
    setIsDeleteConfirmDialogOpen(false);
    deletePhotographyData(photography)
  };

  const handleDeleteConfirmClose = () => {
    setIsDeleteConfirmDialogOpen(false);
  };

  const handlePhotographyClick = (photography) => {
    photography.customer = customers.find((customer) => {
      return customer.id == photography.customerID;
    });
    navigate(PATH.PHOTOGRAPHY_DETAIL + photography.id, {state: {photography:photography}});
  };

  const handlePhotographyDelete = (photography) => {
    setDeleteTargetPhotography(photography);
    setIsDeleteConfirmDialogOpen(true);
  };

  return (
    <>
      <ToastContainer />

      <PhotographyDeleteConfirmDialog 
        open={isDeleteConfirmDialogOpen}       
        targetPhotography={deleteTargetPhotography}
        onOK={handleDeleteConfirmOK}
        onCancel={handleDeleteConfirmClose} />

      <Helmet>
        <title> 日光写真館 プリント | 撮影一覧 </title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h5" >
          撮影一覧
        </Typography>
        <CreatePhotograpyDialog
          customers={customers}
          fetchPhotographies={fetchPhotographies}
          fetchCustomers={fetchCustomers} />
      </Stack>

      <Container>
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <PhotographySort onSortChange={(sort) => handleSortChange(sort)} />
          </Stack>
        </Stack>

        <PhotographyList
          photographies={photographies}
          customers={customers}
          onClick={handlePhotographyClick}
          onPhotographyDelete={handlePhotographyDelete} />
      </Container>

    </>
  );
}
