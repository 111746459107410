import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
// amplify
import { API, graphqlOperation } from "aws-amplify";
import { updatePhotography, updatePhoto } from '../graphql/mutations'
// @mui
import { Grid, Button, Container, Typography, Box, Card, CardContent } from '@mui/material';
// components
import PhotoSelectConfirmDialog from '../components/dialog/PhotoSelectConfirmDialog';
import { ToastContainer, toast } from 'react-toastify';
import { PhotoCard } from '../sections/@dashboard/photography';
import Logo from '../components/logo/Logo';
// other
import { STRINGS } from '../strings';
import { SERVICE_INFO, SAMPLE_PRESENT_INFO, OPTION_INFO, COPY_INFO } from '../constants';
import { PATH } from '../constants';
import { NikkoUtil } from '../utils';

// ----------------------------------------------------------------------

const wapper = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh'
};

const util = new NikkoUtil();

export default function PhotoSelectConfirmPage() {
  const location = useLocation()
  const [photography, setPhotography] = React.useState(location.state.photography)
  const [uniqueTitle, setUniqueTitle] = React.useState('');
  const [selectedCoverPhotos, setSelectedCoverPhotos] = React.useState([]);
  const [selectedBigPhotos, setSelectedBigPhotos] = React.useState([]);
  const [selectedSmallPhotos, setSelectedSmallPhotos] = React.useState([]);
  const [selectedInsidePhotos, setSelectedInsidePhotos] = React.useState([]);
  const [selectedUniquePhotos, setSelectedUniquePhotos] = React.useState([]);
  const [selectedServicePhotos, setSelectedServicePhotos] = React.useState([]);
  const [selectedPresentPhotos, setSelectedPresentPhotos] = React.useState([]);
  const [selectedOptionPhotos, setSelectedOptionPhotos] = React.useState([]);
  const [selectedCopyPhotos, setSelectedCopyPhotos] = React.useState([]);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    console.log(photography);
    // 撮影情報を取得
    let coverPhotos = [];
    let bigPhotos = [];
    let smallPhotos = [];
    let insidePhotos = [];
    let uniquePhotos = [];
    let servicePhotos = [];
    let presentPhotos = [];
    let optionPhotos = [];
    let copyPhotos = [];

    photography.photos.map((photo) => {
      if (photo.isCoverPhoto) {
        coverPhotos.push(photo);
      }
      if (photo.isBigPhoto) {
        bigPhotos.push(photo);
      }
      if (photo.isSmallPhoto) {
        smallPhotos.push(photo);
      }
      if (photo.isInsideCoverPhoto) {
        insidePhotos.push(photo);
      }
      if (photo.isUniqueItemPhoto) {
        uniquePhotos.push(photo);
      }
      if (photo.servicePhotoTypes.length > 0) {
        servicePhotos.push(photo);
      }
      if (photo.isSamplePresentPhoto) {
        presentPhotos.push(photo);
      }
      if (photo.optionPhotoTypes.length > 0) {
        optionPhotos.push(photo);
      }
      if (photo.copyTypes.length > 0) {
        copyPhotos.push(photo);
      }
    });

    setSelectedCoverPhotos(coverPhotos);
    setSelectedBigPhotos(bigPhotos);
    setSelectedSmallPhotos(smallPhotos);
    setSelectedInsidePhotos(insidePhotos);
    setSelectedUniquePhotos(uniquePhotos);

    servicePhotos.sort((a,b) => {
      let aIdx = 1000;
      a.servicePhotoTypes.map((type) => {
        let tmpIdx = photography.selectServicePhotoTypes.indexOf(type);
        if (aIdx >= tmpIdx) {
          aIdx = tmpIdx;
        }
      });
      let bIdx = 1000;
      b.servicePhotoTypes.map((type) => {
        let tmpIdx = photography.selectServicePhotoTypes.indexOf(type);
        if (bIdx >= tmpIdx) {
          bIdx = tmpIdx;
        }
      });
      return aIdx > bIdx ? 1 : -1;
    });
    setSelectedServicePhotos(servicePhotos);
    setSelectedPresentPhotos(presentPhotos);
    setSelectedOptionPhotos(optionPhotos);
    setSelectedCopyPhotos(copyPhotos);
    setUniqueTitle(NikkoUtil.getUniqueTitle(photography.title));
  }, [])

  const generateServiceImgLabel = (typeArray) => {
    let retString = '';
    let serviceNames = [];
    typeArray.map((type) => {
      let serviceIndex = photography.selectServicePhotoTypes.indexOf(type) + 1;
      let serviceName = SERVICE_INFO[type.split('&')[0]].name;
      serviceNames.push(serviceIndex + '.' + serviceName);
    });
    retString = serviceNames.join(' / ');

    return retString;
  }

  const generatePresentImgLabel = () => {
    return SAMPLE_PRESENT_INFO[photography.selectSamplePresent].name;
  }

  const generateOptionImgLabel = (typeArray) => {
    let retString = '';
    let optionNames = [];
    typeArray.map((type) => {
      let optionIndex = photography.selectOptionPhotoTypes.indexOf(type) + 1;
      let optionName = OPTION_INFO[type.split('&')[0]].name;
      optionNames.push(optionIndex + '.' + optionName);
    });
    retString = optionNames.join(' / ');
    return retString;
  }

  const generateCopyImgLabel = (typeArray) => {
    let retString = '';
    let copyNames = [];
    typeArray.map((type) => {
      console.log(type);
      copyNames.push(COPY_INFO[type.key].name + ' ' + type.count + '枚');
    });
    retString = copyNames.join(' / ');
    return retString;
  }

  const clearLocalStorage = () => {
    localStorage.removeItem('photography')
    localStorage.removeItem('photos');
    localStorage.removeItem('photoAndCheckedList');
    localStorage.removeItem('currentServiceNum');
    localStorage.removeItem('currentOptionNum');
    localStorage.removeItem('optionCountList');
    localStorage.removeItem('copyCountList');
  }

  const handleBackButton = () => {
    navigate(-1);
  };
  const handleDoneButton = () => {
    setIsConfirmDialogOpen(true);
  };

  const handleConfirmDialogOK = async (comment) => {
    setIsConfirmDialogOpen(false);

    // localStorage を削除しておく
    clearLocalStorage();

    // photograpy を保存
    photography.isSelected = true;  

    await API.graphql(
      graphqlOperation(updatePhotography, {
        input: {
          id: photography.id,
          selectOptionPhotoTypes: photography.selectOptionPhotoTypes,
          isSelected: true,
          isPrintConfirmed: false,
          comment: comment
        }
      }),
    ).then(
        response => {
        }
    ).catch(err => {
      // rollback
      console.error("updatePhotography", err)
      toast.error(STRINGS.TOAST_UPDATE_PHOTOGRAPY_ERR, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      return
    });

    let count = photography.photos.length;
    console.log(photography.photos);    
    photography.photos.map((photo) => {
      let copyTypeList = [];
      photo.copyTypes.map((copyType) => {
        copyTypeList.push(JSON.stringify(copyType));
      });
      API.graphql(
        graphqlOperation(updatePhoto, {
          input: {
            id: photo.id,
            isCoverPhoto: photo.isCoverPhoto,
            isBigPhoto: photo.isBigPhoto,
            isSmallPhoto: photo.isSmallPhoto,
            isInsideCoverPhoto: photo.isInsideCoverPhoto,
            isUniqueItemPhoto: photo.isUniqueItemPhoto,
            isServicePhoto: photo.isServicePhoto,
            isSamplePresentPhoto: photo.isSamplePresentPhoto,
            isOptionPhoto: photo.isOptionPhoto,
            isCopyPhoto: photo.isCopyPhoto,
            servicePhotoTypes: photo.servicePhotoTypes,
            optionPhotoTypes: photo.optionPhotoTypes,
            copyTypes: copyTypeList
          }
        }),
      ).then(
          response => {
            count--;
            if (count == 0) {
              navigate(PATH.SELECT_DONE + photography.id);
            }
          }
      ).catch(err => {
        // rollback
        // console.error("updatePhoto", err)
        toast.error(STRINGS.TOAST_UPDATE_PHOTOGRAPY_ERR, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
        return
      });
    });
  }

  const handleConfirmDialogClose = () => {
    setIsConfirmDialogOpen(false);
  };

  return (
    <>
      <Logo disabledLink />
      <Helmet>
        <title> 日光写真館 プリント | 写真確認 </title>
      </Helmet>

      <ToastContainer />

      <PhotoSelectConfirmDialog
          open={isConfirmDialogOpen}
          onSelectOK={handleConfirmDialogOK}
          onClose={handleConfirmDialogClose} />

      <Container >
        <Typography variant='body2' sx={{ fontWeight: 'bold' }} margin={2}>選択した写真を確認してください</Typography>

        { selectedCoverPhotos.length > 0 &&
        <Box>
          <Typography variant='h4' color='common.white' backgroundColor={'#4169e1'}>表紙</Typography>
          <Grid container spacing={3}>
            {selectedCoverPhotos.map((photo) => (
              <Grid item xs={12} sm={6} md={3} key={photo.id}>
                <PhotoCard
                  key={photo.id}
                  photo={photo}
                  photography={photography}
                  isSelectable={false}
                  isSimpleDisp={true} />
              </Grid>
            ))}
          </Grid>
        </Box>
        }
        { selectedBigPhotos.length > 0 &&
        <Box>
          <Typography variant='h4' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>大写真</Typography>
          <Grid container spacing={3}>
          {selectedBigPhotos.map((photo) => (
            <Grid item xs={12} sm={6} md={3} key={photo.id}>
              <PhotoCard
                key={photo.id}
                photo={photo}
                photography={photography}
                isSelectable={false}
                isSimpleDisp={true} />
            </Grid>
          ))}
          </Grid>
        </Box>
        }
        { selectedSmallPhotos.length > 0 &&
        <Box>
          <Typography variant='h4' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>小写真</Typography>
          <Grid container spacing={3}>
          {selectedSmallPhotos.map((photo) => (
            <Grid item xs={12} sm={6} md={3} key={photo.id}>
              <PhotoCard
                key={photo.id}
                photo={photo}
                photography={photography}
                isSelectable={false}
                isSimpleDisp={true} />
            </Grid>
          ))}
          </Grid>
        </Box>
        }
        { selectedInsidePhotos.length > 0 &&
        <Box>
          <Typography variant='h4' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>中表紙写真</Typography>
          <Grid container spacing={3}>
          {selectedInsidePhotos.map((photo) => (
            <Grid item xs={12} sm={6} md={3} key={photo.id}>
              <PhotoCard
                key={photo.id}
                photo={photo}
                photography={photography}
                isSelectable={false}
                isSimpleDisp={true} />
            </Grid>
          ))}
          </Grid>
        </Box>
        }
        { selectedUniquePhotos.length > 0 &&
          <Box>
            <Typography variant='h6' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>{uniqueTitle}</Typography>
            <Grid container spacing={3}>
            {selectedUniquePhotos.map((photo) => (
              <Grid item xs={12} sm={6} md={3} key={photo.id}>
                <PhotoCard
                  key={photo.id}
                  photo={photo}
                  isSelectable={false}
                  isSimpleDisp={true} />
              </Grid>
            ))}
            </Grid>
          </Box>
        }
        { selectedServicePhotos.length > 0 &&
        <Box>
          <Typography variant='h4' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>サービス</Typography>
          <Grid container spacing={3}>
          {selectedServicePhotos.map((photo) => (
            <Grid item xs={12} sm={6} md={3} key={photo.id}>
              <PhotoCard
                key={photo.id}
                photo={photo}
                photography={photography}
                isSelectable={false}
                isSimpleDisp={true}
                imgLabel={generateServiceImgLabel(photo.servicePhotoTypes)} />
            </Grid>
          ))}
          </Grid>
        </Box>
        }
        { selectedPresentPhotos.length > 0 &&
        <Box>
          <Typography variant='h4' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>サンプル許可プレゼント</Typography>
          <Grid container spacing={3}>
          {selectedPresentPhotos.map((photo) => (
            <Grid item xs={12} sm={6} md={3} key={photo.id}>
              <PhotoCard
                key={photo.id}
                photo={photo}
                photography={photography}
                isSelectable={false}
                isSimpleDisp={true}
                imgLabel={generatePresentImgLabel()} />
            </Grid>
          ))}
          </Grid>
        </Box>
        }
        { selectedOptionPhotos.length > 0 &&
        <Box>
          <Typography variant='h4' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>追加オプション</Typography>
          <Grid container spacing={3}>
          {selectedOptionPhotos.map((photo) => (
            <Grid item xs={12} sm={6} md={3} key={photo.id}>
              <PhotoCard
                key={photo.id}
                photo={photo}
                photography={photography}
                isSelectable={false}
                isSimpleDisp={true}
                imgLabel={generateOptionImgLabel(photo.optionPhotoTypes)} />
            </Grid>
          ))}
          </Grid>
        </Box>
        }
        { selectedCopyPhotos.length > 0 &&
        <Box>
          <Typography variant='h4' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>焼き増し</Typography>
          <Grid container spacing={3}>
          {selectedCopyPhotos.map((photo) => (
            <Grid item xs={12} sm={6} md={3} key={photo.id}>
              <PhotoCard
                key={photo.id}
                photo={photo}
                photography={photography}
                isSelectable={false}
                isSimpleDisp={true}
                imgLabel={generateCopyImgLabel(photo.copyTypes)} />
            </Grid>
          ))}
          </Grid>
        </Box>
        }
      </Container>

      <Box marginTop={3}></Box>
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Button variant="outlined" fullWidth={true} 
              onClick={
                (event) => {
                  handleBackButton();
                }
              }>
              戻る
              </Button>
            </Grid>
            <Grid item xs={4}>
            </Grid>
            <Grid item xs={4}>
              <Button variant="contained" fullWidth={true}
              onClick={
                (event) => {
                  handleDoneButton();
                }
              }>
              送信する
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

    </>
  );
}
