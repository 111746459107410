/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_appsync_graphqlEndpoint": "https://7muu4oelxbbwhm36sy5thqemia.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-s4vcowziebb6hdnphsytswj7ni",
    "aws_cognito_identity_pool_id": "ap-northeast-1:5cb40ba0-9068-495f-bb26-40561828d2c8",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_GmeYKhpdW",
    "aws_user_pools_web_client_id": "1ojorklttp061kcg9v0jssrfcs",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "nikkophotoapp0d824cf2531741f08d018f9f819ccca2213649-main",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
