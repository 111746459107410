/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPhotography = /* GraphQL */ `
  query GetPhotography($id: ID!) {
    getPhotography(id: $id) {
      id
      title
      course
      isSelected
      isPrintConfirmed
      shootingDate
      photos {
        items {
          id
          name
          photographyID
          isCoverPhoto
          isBigPhoto
          isSmallPhoto
          isInsideCoverPhoto
          isAlbumPhoto
          isUniqueItemPhoto
          isServicePhoto
          servicePhotoTypes
          isOptionPhoto
          optionPhotoTypes
          isCopyPhoto
          copyTypes
          isSamplePresentPhoto
          s3path
          thumbnailS3Path
          createdAt
          updatedAt
        }
        nextToken
      }
      selectServicePhotoTypes
      customerID
      selectOptionPhotoTypes
      customer {
        id
        family_name
        first_name
        prefecture
        municipality
        address
        building
        zipcode
        tel
        createdAt
        updatedAt
      }
      selectCopyPhoto
      selectSamplePresent
      isUniqueItem
      description
      comment
      s3path
      createdAt
      updatedAt
      photographyCustomerId
    }
  }
`;
export const listPhotographies = /* GraphQL */ `
  query ListPhotographies(
    $filter: ModelPhotographyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotographies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        course
        isSelected
        isPrintConfirmed
        shootingDate
        photos {
          nextToken
        }
        selectServicePhotoTypes
        customerID
        selectOptionPhotoTypes
        customer {
          id
          family_name
          first_name
          prefecture
          municipality
          address
          building
          zipcode
          tel
          createdAt
          updatedAt
        }
        selectCopyPhoto
        selectSamplePresent
        isUniqueItem
        description
        comment
        s3path
        createdAt
        updatedAt
        photographyCustomerId
      }
      nextToken
    }
  }
`;
export const getPhoto = /* GraphQL */ `
  query GetPhoto($id: ID!) {
    getPhoto(id: $id) {
      id
      name
      photographyID
      isCoverPhoto
      isBigPhoto
      isSmallPhoto
      isInsideCoverPhoto
      isAlbumPhoto
      isUniqueItemPhoto
      isServicePhoto
      servicePhotoTypes
      isOptionPhoto
      optionPhotoTypes
      isCopyPhoto
      copyTypes
      isSamplePresentPhoto
      s3path
      thumbnailS3Path
      createdAt
      updatedAt
    }
  }
`;
export const listPhotos = /* GraphQL */ `
  query ListPhotos(
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        photographyID
        isCoverPhoto
        isBigPhoto
        isSmallPhoto
        isInsideCoverPhoto
        isAlbumPhoto
        isUniqueItemPhoto
        isServicePhoto
        servicePhotoTypes
        isOptionPhoto
        optionPhotoTypes
        isCopyPhoto
        copyTypes
        isSamplePresentPhoto
        s3path
        thumbnailS3Path
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const photosByPhotographyID = /* GraphQL */ `
  query PhotosByPhotographyID(
    $photographyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    photosByPhotographyID(
      photographyID: $photographyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        photographyID
        isCoverPhoto
        isBigPhoto
        isSmallPhoto
        isInsideCoverPhoto
        isAlbumPhoto
        isUniqueItemPhoto
        isServicePhoto
        servicePhotoTypes
        isOptionPhoto
        optionPhotoTypes
        isCopyPhoto
        copyTypes
        isSamplePresentPhoto
        s3path
        thumbnailS3Path
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      family_name
      first_name
      prefecture
      municipality
      address
      building
      zipcode
      tel
      createdAt
      updatedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        family_name
        first_name
        prefecture
        municipality
        address
        building
        zipcode
        tel
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
