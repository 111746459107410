import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Card, Stack, Typography, CardContent, Button } from '@mui/material';
//
import { StyledSelectFooter } from './styles';
import { Link } from 'react-router-dom';
import { PATH } from '../../constants';

// ----------------------------------------------------------------------

const SelectFooter = forwardRef((
  { color = 'default', variant = 'soft', startIcon, endIcon, sx, remainingNumber,
  isNegativeButtonVisible = true, isNextButtonEnable = false, isDoneButton = false,
  isRemainingNumberDisp = true, isOptionResetButtonDisp,
  onPositiveButtonClick, onNegativeButtonClick, onOptionReset,
  ...other }, ref) => {
  const theme = useTheme();

  return (
    <StyledSelectFooter
      ref={ref}
      component="span"
      ownerState={{ color, variant }}
      sx={{
        ...(startIcon && { pl: 0.75 }),
        ...(endIcon && { pr: 0.75 }),
        ...sx,
      }}
      theme={theme}
      {...other}
    >

      <Card {...other}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            {isNegativeButtonVisible &&
              <Button variant="outlined" fullWidth={true} 
              onClick={
                (event) => {
                  onNegativeButtonClick();
                }
              }>戻る
              </Button>
            }
          </Grid>
          <Grid item xs={4}>
          </Grid>
          <Grid item xs={4}>
            <Button variant="contained" fullWidth={true} disabled={!isNextButtonEnable}
              onClick={
                (event) => {
                  onPositiveButtonClick();
                }
              }> {isDoneButton ? "決定" : "次へ"}
            </Button>
          </Grid>
          <Grid item xs={6} marginLeft={-2}>
            {isOptionResetButtonDisp &&
            <Button variant="text" fullWidth={true} size="small"
              onClick={
                (event) => {
                  onOptionReset();
                }
              }>オプションをリセット
            </Button>
            }
          </Grid>
          <Grid item xs={2}>
          </Grid>
          {isRemainingNumberDisp &&
            <Grid item xs={4} marginLeft={2}>
              {remainingNumber >= 0 ?
              <Typography variant='overline' sx={{ fontWeight: 'bold' }}>あと {remainingNumber} 枚</Typography> :
              <Typography variant='overline' style={{ color: "red", fontWeight: 'bold' }}>あと {remainingNumber} 枚</Typography>
              }
            </Grid>
          }
        </Grid>

      </CardContent>
      </Card>
    </StyledSelectFooter>
  );
});

SelectFooter.propTypes = {
  sx: PropTypes.object,
  endIcon: PropTypes.node,
  children: PropTypes.node,
  startIcon: PropTypes.node,
  variant: PropTypes.oneOf(['filled', 'outlined', 'ghost', 'soft']),
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'info', 'success', 'warning', 'error']),
  remainingNumber: PropTypes.number,
  isBackButtonVisible: PropTypes.bool,
  isNextButtonEnable:  PropTypes.bool,
  isDoneButton: PropTypes.bool, // If false, NextButton is displayed.
  onBackButtonClick: PropTypes.func,
  onNextButtonClick: PropTypes.func,
  onDoneButtonClick: PropTypes.func
};

export default SelectFooter;
