import * as React from 'react';
import PropTypes from 'prop-types';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Card, Grid, Checkbox, Typography, Stack,  Avatar, CardActionArea, CardContent } from '@mui/material';
import { FormGroup, FormControlLabel, } from '@mui/material';
// amplify
import { Storage } from "aws-amplify";
// component
import Label from '../../../components/label';
// other
import { ASSET } from '../../../constants';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  position: 'absolute',
});

const StyledCardContent = styled('CardContent')({
  height: "95%",
})

const StyledAvater = styled('Avater')({
  width: 20,  // 任意の幅を指定
  height: 20, // 任意の高さを指定
  fontSize: 12, // テーマのデフォルトフォントサイズを使用
});

// ----------------------------------------------------------------------

PhotoCard.propTypes = {
  photo: PropTypes.object.isRequired,
  photography: PropTypes.object,
  index: PropTypes.number,
  isChecked: PropTypes.bool,
  isSelectable: PropTypes.bool,
  onClick: PropTypes.func,
  onChanged:  PropTypes.func
};

export default function PhotoCard(
  { photo, index, imgLabel='', isChecked = false, isSelectable, isSimpleDisp = false, onClick, onChanged }) {
  const [coverImage, setCoverImage] = React.useState(ASSET.NO_IMAGE);

  React.useEffect(() => {
    // S3 
    Storage.get(photo.thumbnailS3Path)
    .then(result => {
      if (result) {
        setCoverImage(result);
      }
    })
    .catch(err => {
      console.log(err)
    });
  }, [])

  const handleCheckChanged = (event, index) => {
    onChanged(event, index);
  }

  const handleImageError = (event) => {
    // サムネイルが出来上がっていないパターン
    event.target.onError = null;
    Storage.get(photo.s3path)
    .then(result => {
      if (result) {
        setCoverImage(result);
      }
    })
    .catch(err => {
      console.log(err)
    });
  }

  return (
      <Card>
        <CardActionArea onClick={() => {onClick(photo)}} >
          { imgLabel != '' ?
          <Box sx={{ pt: '100%', position: 'relative' }}>
              <Label
                variant="filled"
                color={'info'}
                sx={{
                  zIndex: 9,
                  top: 16,
                  right: 16,
                  position: 'absolute',
                  textTransform: 'uppercase',
                }}
              >
                {imgLabel}
              </Label>
          <StyledProductImg src={coverImage} onError={event => {handleImageError(event)}} sx={{ position: 'absolute' }} />
          </Box>
          :
          <Box sx={{ pt: '100%', position: 'relative' }}>
            <StyledProductImg src={coverImage} onError={event => {handleImageError(event)}} sx={{ position: 'absolute' }} />
          </Box>
          }
        </CardActionArea>
        
          <Stack >
          {isSelectable ? 
            <FormGroup>
              <Typography noWrap>
                <FormControlLabel control={
                  <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                            disabled={!isSelectable}
                            checked={isChecked}
                            onChange={
                              (event) => {
                                handleCheckChanged(event, index)
                              }
                            }
                  />}
                  label={photo.name} sx={{ '& .MuiFormControlLabel-label': { fontSize: 14 } }} />
              </Typography>
            </FormGroup> :
            <Typography variant='subtitle1' marginTop={2}>
              {photo.name}
            </Typography>
          }
          </Stack>
          { !isSimpleDisp &&
            <Grid container>
              { photo.isCoverPhoto &&
                  <Avatar sx={{ width: 30, height: 30, bgcolor: "#4169e1" }}>表紙</Avatar>
              }
              { photo.isBigPhoto &&
                  <Avatar sx={{ width: 30, height: 30, bgcolor: "#4169e1" }}>大</Avatar>
              }
              { photo.isSmallPhoto &&
                  <Avatar sx={{ width: 30, height: 30, bgcolor: "#4169e1" }}>小</Avatar>
              }
              { photo.isInsideCoverPhoto &&
                  <Avatar sx={{ width: 30, height: 30, bgcolor: "#4169e1" }}>中表</Avatar>
              }
              { photo.isUniqueItemPhoto &&
                  <Avatar sx={{ width: 30, height: 30, bgcolor: "#4169e1" }}>百</Avatar>
              }
              { photo.servicePhotoTypes != null && photo.servicePhotoTypes.length > 0 &&
                  <Avatar sx={{ width: 30, height: 30, bgcolor: "#4169e1" }}>サ</Avatar>
              }
              { photo.isSamplePresentPhoto &&
                  <Avatar sx={{ width: 30, height: 30, bgcolor: "#4169e1" }}>プ</Avatar>
              }
              { photo.optionPhotoTypes != null && photo.optionPhotoTypes.length > 0 &&
                  <Avatar sx={{ width: 30, height: 30, bgcolor: "#4169e1" }}>追</Avatar>
              }
              { photo.isCopyPhoto && isChecked &&
                  <Avatar sx={{ width: 30, height: 30, bgcolor: "#4169e1" }}>増</Avatar>
              }
            </Grid>
          }

      </Card>
  );
}
