import React, { useState } from "react";
import PropTypes from 'prop-types';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
// @mui
import { Modal, Button, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
// other
import { NikkoUtil } from '../../utils';

const modalPortlateStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
}

const modalLandscapeStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
};

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

PhotoImageZoomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  imageUrls: PropTypes.array,
  index: PropTypes.number,
  onClose: PropTypes.func.isRequired
};

export default function PhotoImageZoomModal({isOpen, imageUrl, onClose}) {
  // const transformComponentRef = React.useRef;

  const handleDownload = () => {
    NikkoUtil.downloadImage(imageUrl);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
    <Box>
      <Box sx={modalPortlateStyle}>
        <TransformWrapper sx={{ position: 'absolute' }} limitToBounds={true} maxPositionX='-20%' >
          <React.Fragment>
              <TransformComponent>
                <StyledProductImg src={imageUrl} />
              </TransformComponent>
          </React.Fragment>
        </TransformWrapper>
      </Box>
      <Box justifyContent="flex-end" display="flex" sx={{ backgroundColor: 'white', opacity: 0.8, align: 'right'}}>
        <IconButton aria-label='delete' size='large' onClick={handleDownload}>
          <FileDownloadIcon fontSize="inherit" color="white" />
        </IconButton>
        <IconButton aria-label='delete' size='large' onClick={handleClose}>
          <CloseIcon fontSize="inherit" color="white" />
        </IconButton>
      </Box>
    </Box>
    </Modal>
  );
}

