import React, { Suspense } from 'react'
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import SimpleLayout from '../layouts/simple';
//
import CustomerPage from '../pages/CustomerPage';
import LoginPage from '../pages/LoginPage';
import Page404 from '../pages/Page404';
import PhotographyPage from '../pages/PhotographyPage';
import PhotographyDetailPage from '../pages/PhotographyDetailPage';
import PhotoSelectPage from '../pages/PhotoSelectPage';
import PhotoSelectConfirmPage from '../pages/PhotoSelectConfirmPage';
import PhotoSelectDonePage from '../pages/PhotoSelectDonePage';
import { PATH } from '../constants';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '',
      element: <Navigate to="/dashboard/photography" />, index: true ,
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: PATH.SELECT_PHOTO + ':photographyID',
      element: <PhotoSelectPage />,
    },
    {
      path: PATH.SELECT_CONFIRM,
      element: <PhotoSelectConfirmPage />,
    },
    {
      path: PATH.SELECT_DONE + ':photographyID',
      element: <PhotoSelectDonePage />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/photography" />, index: true },
        { path: 'photography', element: <PhotographyPage /> },
        { path: 'customer', element: <CustomerPage /> },
        { path: 'photography/:photographyID', element: <PhotographyDetailPage /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/simple" />,
          index: true
        },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return <Suspense fallback={<div>Loading</div>}>{routes}</Suspense>
  // return routes;
}
