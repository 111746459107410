import * as React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useDropzone } from 'react-dropzone';
// @mui
import { Grid, Button, Box, Container, Stack, Typography } from '@mui/material';
// amplify
import { API, graphqlOperation, Storage } from "aws-amplify";
import { listPhotos, getPhotography } from "../graphql/queries";
import { createPhoto, updatePhotography } from '../graphql/mutations'
// components
import { PhotoCard } from '../sections/@dashboard/photography';
import PhotoImageZoomModal from '../components/modal/PhotoImageZoomModal';
import 'react-circular-progressbar/dist/styles.css';
// other
import { ToastContainer, toast } from 'react-toastify';
import ReactToPrint from 'react-to-print';
import { STRINGS } from '../strings';
import { PATH } from '../constants';
import { PHOTOGRAPHY_INFO, SERVICE_INFO, SAMPLE_PRESENT_INFO, OPTION_INFO, COPY_INFO } from '../constants';
import { NikkoUtil } from '../utils';

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'oldest', label: 'Oldest' },
];

// ----------------------------------------------------------------------

export default function PhotographyDetailPage() {
  const { photographyID } = useParams();
  const componentRef = React.useRef();
  const location = useLocation()

  const [photography, setPhotography] = React.useState()
  const [photos, setPhotos] = React.useState([]);
  const [phogographyTitle, setPhogographyTitle] = React.useState('');
  const [photographyDescription, setPhotographyDescription] = React.useState('');
  const [photographyComment, setPhotographyComment] = React.useState('');
  const [uploadingFiles, setUploadingFiles] = React.useState([]);
  const [isPhotoZoomModalOpen, setIsPhotoZoomModalOpen] = React.useState(false);
  const [imageViewerUrl, setImageViewerUrl] = React.useState([]);
  const [isSelected, setIsSelected] = React.useState(false);
  const [isPrintConfirmed, setIsPrintConfirmed] = React.useState(false);
  const [uniqueTitle, setUniqueTitle] = React.useState('');
  const [photoCount, setPhotoCount] = React.useState(0);

  const [selectedCoverPhotos, setSelectedCoverPhotos] = React.useState([]);
  const [selectedBigPhotos, setSelectedBigPhotos] = React.useState([]);
  const [selectedSmallPhotos, setSelectedSmallPhotos] = React.useState([]);
  const [selectedInsidePhotos, setSelectedInsidePhotos] = React.useState([]);
  const [selectedUniquePhotos, setSelectedUniquePhotos] = React.useState([]);
  const [selectedServicePhotos, setSelectedServicePhotos] = React.useState([]);
  const [selectedPresentPhotos, setSelectedPresentPhotos] = React.useState([]);
  const [selectedOptionPhotos, setSelectedOptionPhotos] = React.useState([]);
  const [selectedCopyPhotos, setSelectedCopyPhotos] = React.useState([]);

  let uploadTmpUploadingFiles;
  let selectSamplePresent = '';

  const dropzoneFrame = {
    width: '100%',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#cccccc',
    borderStyle: 'dashed',
    backgroundColor: '#eeeeee',
    color: '#888888',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  }

  const dropzone = {
    width: '100%',
    minheight: '150px',
  }

  const thumbsContainer = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };

  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  };

  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };

  const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
  };

  React.useEffect(() => {
    if (location.state.photography) {
      var photography = location.state.photography;
      setPhotography(photography);
      setIsSelected(photography.isSelected);
      setIsPrintConfirmed(photography.isPrintConfirmed);
      setPhogographyTitle(photography.customer.family_name + photography.customer.first_name + '様 ' + PHOTOGRAPHY_INFO[photography.title].name + ' ' + photography.course);
      setPhotographyDescription(photography.description);
      setPhotographyComment(photography.comment);
      setUniqueTitle(NikkoUtil.getUniqueTitle(photography.title));
    } else {
      fetchPhotography();
    }
    // 写真のリストを取得
    fetchPhotos();
  }, [])

  React.useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => uploadingFiles.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  const fetchPhotography = async () => {
    // 撮影情報を取得
    try {
      var photographyResult = await API.graphql({
        query: getPhotography,
        variables: { id: photographyID }
      });
      var fetchPhotography = photographyResult.data.getPhotography;
      setPhotography(fetchPhotography);
      setIsSelected(fetchPhotography.isSelected);
      setIsPrintConfirmed(fetchPhotography.isPrintConfirmed);
      setPhotographyDescription(fetchPhotography.description);
      setPhotographyComment(fetchPhotography.comment);
      setUniqueTitle(NikkoUtil.getUniqueTitle(photography.title));
    } catch (err) {
        console.error('error:', err);
    }
  }

  const fetchPhotos = async () => {
    // 写真のリストを取得
    try {
      console.log(photographyID)
      const filter = {
        photographyID: {
          'eq': photographyID,
        }
      }

      const fetchPhotos = await API.graphql(
        graphqlOperation(listPhotos, {filter: filter, limit: 10000})
      );
      // 初回データを保持
      var allPhotos = fetchPhotos.data.listPhotos.items;
      console.log(fetchPhotos);
      // 続きを取得
      var nextToken = fetchPhotos.data.listPhotos.nextToken;
      while (nextToken != null) {
        const nextPageQuery = await API.graphql(
          graphqlOperation(listPhotos, { filter: filter, limit: 10000, nextToken })
        );
        console.log(nextPageQuery);
        const nextPageData = nextPageQuery.data;
        allPhotos = allPhotos.concat(nextPageData.listPhotos.items);
        nextToken = nextPageData.listPhotos.nextToken;
      }

      var array = allPhotos.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });

      setPhotoCount(array.length);
      setPhotos(array)
      settingSelectedPhotos(array);
    } catch (err) {
      console.error('error:', err);
    }
  }

  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/*': []
    },
    multiple: true,
    onDrop: acceptedFiles => {
      setUploadingFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });
  
  const uploadPhoto = (file) => {
    // console.log(file);
    var filename = file.path.split('/').pop().split('\\').pop()

    // console.log(photographyID)
    // S3 へアップロード
    let photoS3Path = 'image/' + photographyID + '/' + filename;
    let thumbnailS3Path = 'thumbnail/' + photographyID + '/' + filename;
    // console.log(photoS3Path);

    uploadTmpUploadingFiles = [...uploadingFiles];
    var uploadCount = uploadTmpUploadingFiles.length;

    Storage.put(photoS3Path, file)
    .then(result => {
      // Thumbnail は Lambda で作成
      // S3 アップロードの完了後に Dynamo へ登録
      const photo = {
        name: file.name,
        photographyID: photographyID,
        s3path: photoS3Path,
        thumbnailS3Path: thumbnailS3Path,
        isCoverPhoto: false,
        isBigPhoto: false,
        isSmallPhoto: false,
        isInsideCoverPhoto: false,
        isAlbumPhoto: false,
        isServicePhoto: false,
        servicePhotoTypes: [],
        isSamplePresentPhoto: false,
        isOptionPhoto: false,
        optionPhotoTypes: [],
        isCopyPhoto: false,
        copyTypes: []
      }
      const photoItem = API.graphql(
        graphqlOperation(createPhoto, {
          input: photo
        }),
      )
      .then(result => {
        uploadCount--;
        var filterResult = uploadTmpUploadingFiles.filter(function( item ) {
          return result.data.createPhoto.name != item.name;
        });
        uploadTmpUploadingFiles = filterResult;
        setUploadingFiles(uploadTmpUploadingFiles);
        fetchPhotos();
      })
      .catch(err => {
        // rollback
        console.error("createPhoto", err)
        toast.error(STRINGS.TOAST_UPLOAD_PHOTO_ERR, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
      });
    })
    .catch(err => {
      // rollback
      console.error("S3 Put", err);
      toast.error(STRINGS.TOAST_UPLOAD_PHOTO_ERR, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    });
  }

  const settingSelectedPhotos = (photoArray) => {
    // 撮影情報を取得
    let coverPhotos = [];
    let bigPhotos = [];
    let smallPhotos = [];
    let insidePhotos = [];
    let uniquePhotos = [];
    let servicePhotos = [];
    let presentPhotos = [];
    let optionPhotos = [];
    let copyPhotos = [];

    photoArray.map((photo) => {
      if (photo.isCoverPhoto) {
        coverPhotos.push(photo);
      }
      if (photo.isBigPhoto) {
        bigPhotos.push(photo);
      }
      if (photo.isSmallPhoto) {
        smallPhotos.push(photo);
      }
      if (photo.isInsideCoverPhoto) {
        insidePhotos.push(photo);
      }
      if (photo.isUniqueItemPhoto) {
        uniquePhotos.push(photo);
      }
      if (photo.servicePhotoTypes.length > 0) {
        servicePhotos.push(photo);
      }
      if (photo.isSamplePresentPhoto) {
        presentPhotos.push(photo);
      }
      if (photo.optionPhotoTypes.length > 0) {
        optionPhotos.push(photo);
      }
      if (photo.copyTypes.length > 0) {
        copyPhotos.push(photo);
      }
    });

    setSelectedCoverPhotos(coverPhotos);
    setSelectedBigPhotos(bigPhotos);
    setSelectedSmallPhotos(smallPhotos);
    setSelectedInsidePhotos(insidePhotos);
    setSelectedUniquePhotos(uniquePhotos);
    setSelectedServicePhotos(servicePhotos);
    setSelectedPresentPhotos(presentPhotos);
    setSelectedOptionPhotos(optionPhotos);
    setSelectedCopyPhotos(copyPhotos);    
  }

  const copyToClipboard = async () => {
    var uri = new URL(window.location.href);

    await global.navigator.clipboard.writeText(uri.origin + '/' + PATH.SELECT_PHOTO + photographyID);
    toast.success(STRINGS.TOAST_SELECT_PHOTO_URL_COPY, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const generateServiceImgLabel = (typeArray) => {
    let retString = '';
    let serviceNames = [];
    typeArray.map((type) => {
      let serviceIndex = photography.selectServicePhotoTypes.indexOf(type) + 1;
      let serviceName = SERVICE_INFO[type.split('&')[0]].name;
      serviceNames.push(serviceIndex + '.' + serviceName);
    });
    retString = serviceNames.join(' / ');

    return retString;
  }

  const generatePresentImgLabel = (selectSamplePresent) => {
    if (selectSamplePresent == '') {
      return '';
    }
    return SAMPLE_PRESENT_INFO[selectSamplePresent].name;
  }

  const generateOptionImgLabel = (typeArray) => {
    let retString = '';
    let optionNames = [];
    typeArray.map((type) => {
      let optionIndex = photography.selectOptionPhotoTypes.indexOf(type) + 1;
      let optionName = OPTION_INFO[type.split('&')[0]].name;
      optionNames.push(optionIndex + '.' + optionName);
    });
    retString = optionNames.join(' / ');

    return retString;
  }

  const generateCopyImgLabel = (typeArray) => {
    let retString = '';
    let copyNames = [];
    typeArray.map((typeJson) => {
      let type = JSON.parse(typeJson);
      console.log(type);
      copyNames.push(COPY_INFO[type.key].name + ' ' + type.count + '枚');
    });
    retString = copyNames.join(' / ');

    return retString;
  }

  const handlePrintConfirm = async (status) => {
    setIsPrintConfirmed(status);
    photography.isPrintConfirmed = status;

    await API.graphql(
      graphqlOperation(updatePhotography, {
        input: {
          id: photography.id,
          isPrintConfirmed: photography.isPrintConfirmed
        }
      }),
    ).then(response => {
      }).catch(err => {
        console.error(err);
    });
  }

  const handleImageClick = async (photo) => {
    let resultUrl = '';
    await Storage.get(photo.s3path)
    .then(result => {
      if (result) {
        resultUrl = result;
      }
    })
    .catch(err => {
      console.log(err)
    });
    setImageViewerUrl(resultUrl);
    setIsPhotoZoomModalOpen(true);
  };

  const handleImageClose = (index) => {
    setIsPhotoZoomModalOpen(false);
  };

  return (
    <>
      <ToastContainer />

      <PhotoImageZoomModal
        isOpen={isPhotoZoomModalOpen}
        imageUrl={imageViewerUrl}
        onClose={handleImageClose}
      />

      <Helmet>
        <title> 日光写真館 プリント | 撮影詳細 </title>
      </Helmet>

      <Container>
        <Stack direction="row" justifyContent="space-between" mb={5}>
          <Typography variant="h4">撮影詳細</Typography>
          <br />
        {isSelected ?
          <ReactToPrint
              trigger={() => (
                <Button variant="contained" >
                  選択された写真を印刷する
                </Button>
              )}
            pageStyle="@page { size: A4; margin: 0; }"
            content={() => componentRef.current}
          />
          :
          <Button variant="contained" onClick={copyToClipboard}>
            お客様に通知する URL をコピーする
          </Button>            
        }
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant='h4' textAlign={'left'} marginBottom={3}>
            {phogographyTitle} ({photoCount})
          </Typography>
          { isSelected && (!isPrintConfirmed ?
            <Button variant='outlined' onClick={() => {handlePrintConfirm(true)}}>未確認</Button> :
            <Button variant='contained' onClick={() => {handlePrintConfirm(false)}}>確認済み</Button>)
          }
        </Stack>
        <hr />
        <Typography variant="h6" align='left'>撮影メモ：{photographyDescription}</Typography>
        <Typography variant="h6" align='left'>お客様コメント：{photographyComment}</Typography>
        <hr />
        <br />
        <Box ref={componentRef}>
        { isSelected && selectedCoverPhotos.length > 0 && 
          <Box>
            <Typography variant='h6' color='common.white' backgroundColor={'#4169e1'}>表紙</Typography>
            <Grid container spacing={3}>
              {selectedCoverPhotos.map((photo) => (
                <Grid item xs={12} sm={6} md={3} key={photo.id}>
                  <PhotoCard
                    key={photo.id}
                    photo={photo}
                    onClick={handleImageClick}
                    isSelectable={false}
                    isSimpleDisp={true} />
                </Grid>
              ))}
            </Grid>
          </Box>
        }
        { isSelected && selectedBigPhotos.length > 0 && 
          <Box>
            <Typography variant='h6' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>大写真</Typography>
            <Grid container spacing={3}>
            {selectedBigPhotos.map((photo) => (
              <Grid item xs={12} sm={6} md={3} key={photo.id}>
                <PhotoCard
                  key={photo.id}
                  photo={photo}
                  onClick={handleImageClick}
                  isSelectable={false}
                  isSimpleDisp={true} />
              </Grid>
            ))}
            </Grid>
          </Box>
        }
        { isSelected && selectedSmallPhotos.length > 0 &&
          <Box>
            <Typography variant='h6' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>小写真</Typography>
            <Grid container spacing={3}>
            {selectedSmallPhotos.map((photo) => (
              <Grid item xs={12} sm={6} md={3} key={photo.id}>
                <PhotoCard
                  key={photo.id}
                  photo={photo}
                  onClick={handleImageClick}
                  isSelectable={false}
                  isSimpleDisp={true} />
              </Grid>
            ))}
            </Grid>
          </Box>
        }
        { isSelected && selectedInsidePhotos.length > 0 &&
          <Box>
            <Typography variant='h6' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>中表紙写真</Typography>
            <Grid container spacing={3}>
            {selectedInsidePhotos.map((photo) => (
              <Grid item xs={12} sm={6} md={3} key={photo.id}>
                <PhotoCard
                  key={photo.id}
                  photo={photo}
                  onClick={handleImageClick}
                  isSelectable={false}
                  isSimpleDisp={true} />
              </Grid>
            ))}
            </Grid>
          </Box>
        }
        { isSelected && selectedUniquePhotos.length > 0 &&
          <Box>
            <Typography variant='h6' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>{uniqueTitle}</Typography>
            <Grid container spacing={3}>
            {selectedUniquePhotos.map((photo) => (
              <Grid item xs={12} sm={6} md={3} key={photo.id}>
                <PhotoCard
                  key={photo.id}
                  photo={photo}
                  onClick={handleImageClick}
                  isSelectable={false}
                  isSimpleDisp={true} />
              </Grid>
            ))}
            </Grid>
          </Box>
        }
        { isSelected && selectedServicePhotos.length > 0 &&
          <Box>
            <Typography variant='h6' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>サービス</Typography>
            <Grid container spacing={3}>
            {selectedServicePhotos.map((photo) => (
              <Grid item xs={12} sm={6} md={3} key={photo.id}>
                <PhotoCard
                  key={photo.id}
                  photo={photo}
                  onClick={handleImageClick}
                  isSelectable={false}
                  isSimpleDisp={true}
                  imgLabel={generateServiceImgLabel(photo.servicePhotoTypes)} />
              </Grid>
            ))}
            </Grid>
          </Box>
        }
        { isSelected && selectedPresentPhotos.length > 0 &&
          <Box>
            <Typography variant='h6' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>サンプル許可プレゼント</Typography>
            <Grid container spacing={3}>
            {selectedPresentPhotos.map((photo) => (
              <Grid item xs={12} sm={6} md={3} key={photo.id}>
                <PhotoCard
                  key={photo.id}
                  photo={photo}
                  onClick={handleImageClick}
                  isSelectable={false}
                  isSimpleDisp={true}
                  imgLabel={generatePresentImgLabel(photography.selectSamplePresent)} />
              </Grid>
            ))}
            </Grid>
          </Box>
        }
        { isSelected && selectedOptionPhotos.length > 0 &&
          <Box>
            <Typography variant='h6' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>追加オプション</Typography>
            <Grid container spacing={3}>
            {selectedOptionPhotos.map((photo) => (
              <Grid item xs={12} sm={6} md={3} key={photo.id}>
                <PhotoCard
                  key={photo.id}
                  photo={photo}
                  onClick={handleImageClick}
                  isSelectable={false}
                  isSimpleDisp={true}
                  imgLabel={generateOptionImgLabel(photo.optionPhotoTypes)} />
              </Grid>
            ))}
            </Grid>
          </Box>
        }
        { isSelected && selectedCopyPhotos.length > 0 &&
          <Box>
            <Typography variant='h6' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>焼き増し</Typography>
            <Grid container spacing={3}>
            {selectedCopyPhotos.map((photo) => (
              <Grid item xs={12} sm={6} md={3} key={photo.id}>
                <PhotoCard
                  key={photo.id}
                  photo={photo}
                  onClick={handleImageClick}
                  isSelectable={false}
                  isSimpleDisp={true}
                  imgLabel={generateCopyImgLabel(photo.copyTypes)} />
              </Grid>
            ))}
            </Grid>
          </Box>
        }
        { !isSelected && 
          <Stack direction="row" alignItems="center" justifyContent="center" >
            <section className="container" style={dropzoneFrame}>
              <div {...getRootProps({className: 'dropzone'})} style={dropzone}>
                <input {...getInputProps()} />
                <p>写真をドラッグ＆ドロップしてください</p>
                <aside style={thumbsContainer}>
                {uploadingFiles.map(file => (
                  <div style={thumb} key={file.name}>
                    <div style={thumbInner}>
                      <img
                        src={file.preview}
                        style={img}
                        // Revoke data uri after image is loaded
                        onLoad={() => {
                          URL.revokeObjectURL(file.preview);
                          uploadPhoto(file);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </aside>
              </div>
            </section>
          </Stack>
        }
        </Box>

        <Box>
        <Typography variant='h6' color='common.white' backgroundColor={'#4169e1'} marginTop={3}>撮影写真</Typography>
        <Grid container spacing={3}>
            {photos.map((photo, index) => (
              <Grid item xs={12} sm={6} md={3} key={photo.id}>
                <PhotoCard
                  key={photo.id}
                  photo={photo}
                  index={index}
                  onClick={handleImageClick}
                  isSelectable={false}
                  isSimpleDisp={true} />
              </Grid>
            ))}
        </Grid>
        </Box>

      </Container>
    </>
  );
}
